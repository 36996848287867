import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Dialog,
  DialogContent,
  Card,
  CardContent,
  FormLabel
} from "@mui/material";
import { NewBBPSIcon } from "../../res/NewWebIcons";
import axios from "axios";
import { toast } from "react-toastify";

const NewElectricity2 = () => {
  const [electricityBoards, setElectricityBoards] = useState([]); // To store API response
  const [selectedBoard, setSelectedBoard] = useState("");
  const [consumerNumber, setConsumerNumber] = useState("");
  const [billingUnit, setBillingUnit] = useState("");
  const [billDate, setBillDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [electricityInfo, setElectricityInfo] = useState(null);

  // Placeholder data for modal, replace with actual data from another API if needed
  const [customerName, setCustomerName] = useState("");
  const [amount, setAmount] = useState("");
  const [walletBalance, setWalletBalance] = useState("");
  const [inputLabel, setInputLabel] = useState(""); // For dynamic label
  const [consumerInput, setConsumerInput] = useState(""); // For user input
  const [inputFields, setInputFields] = useState([]); // Store dynamic fields and labels
  const [formValues, setFormValues] = useState({}); // Store user input for all fields

  useEffect(() => {
    // Fetch electricity boards from API
    const fetchElectricityBoards = async () => {
      const token = sessionStorage.getItem('jwtToken');
      try {
        const response = await fetch(
          "https://testapi.ipaisa.co.in/myapp/auth/getAllElecServicePro",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        // Assuming the API returns an array of boards with `name` and `id`
        const boards = data.map((board) => ({
          label: board.name,
          value: board.id,
        }));
        setElectricityBoards(data); // Save the response to state
      } catch (error) {
        console.error("Error fetching electricity boards:", error);
      }
    };

    fetchWalletBalance();
    fetchElectricityBoards();
  }, []);
  const fetchWalletBalance = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const handleProceed = async () => {
    try {
      // Ensure required fields are filled
      if (!selectedBoard) {
        alert("Please select an electricity board.");
        return;
      }

      const selectedData = electricityBoards.find(
        (board) => board.opcode === selectedBoard
      );

      if (!selectedData) {
        alert("Invalid board selected.");
        return;
      }

      const { mplanShortcut } = selectedData;
      const tel = formValues["mn"]; // Assuming 'mn' is the Consumer Number field key

      if (!tel) {
        alert("Please fill the Consumer Number.");
        return;
      }

      const operator = selectedData.mplanShortcut;
      const token = sessionStorage.getItem('jwtToken');
      const url = `https://testapi.ipaisa.co.in/myapp/auth/getElectricityInfo?tel=${tel}&operator=${operator}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Replace with your token
      };

      const response = await fetch(url, {
        method: "GET",
        headers,
        // body: JSON.stringify({ mplanShortcut }), // Add any other required payload if needed
      });

      if (!response.ok) {
        throw new Error("Failed to fetch electricity info.");
      }

      const result = await response.json();
      if (result.status === 1 && result.records?.length > 0) {
        console.log("API Response:", result);

        setElectricityInfo(result.records[0]);
        setCustomerName(electricityInfo.CustomerName || "");
        setAmount(electricityInfo.Billamount || "");
        setBillDate(electricityInfo.Billdate || "");
        setDueDate(electricityInfo.Duedate || "");
        setConsumerNumber(formValues["mn"] || "");
        setBillingUnit(formValues["bu"] || "");

      }
      alert("Electricity info fetched successfully!");
    } catch (error) {
      console.error("Error:", error);
      // alert("Error fetching electricity info. Please try again.");
    }
  };
  const handleCancel = () => {
    setElectricityInfo(null);
    setFormValues({});
  };
  const handleBoardChange = (e) => {
    const selectedOpcode = e.target.value;
    setSelectedBoard(selectedOpcode);

    // Find the selected board in the fetched data
    const selectedData = electricityBoards.find(
      (board) => board.opcode === selectedOpcode
    );

    // Dynamically set input fields based on `inputParams`
    if (selectedData?.inputParams) {
      const fields = Object.entries(selectedData.inputParams).map(
        ([key, label]) => ({ key, label })
      );
      setInputFields(fields);

      // Initialize form values for the dynamic fields
      const initialValues = fields.reduce((acc, field) => {
        acc[field.key] = ""; // Initialize each field's value as empty
        return acc;
      }, {});
      setFormValues(initialValues);
    } else {
      setInputFields([]);
      setFormValues({});
    }
  };

  // Handle user input for dynamic fields
  const handleInputChange = (key, value) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handlePayClick = () => {
    // Assuming electricityInfo contains data from the fetched API response
    if (!electricityInfo) {
      alert("No electricity information available to display.");
      return;
    }

    setCustomerName(electricityInfo.CustomerName || ""); // Ensure fallback if the field is missing
    setAmount(electricityInfo.Billamount || "");
    setBillDate(electricityInfo.Billdate || "");
    setDueDate(electricityInfo.Duedate || "");
    setConsumerNumber(formValues["mn"] || "");
    setBillingUnit(formValues["bu"] || "");

    // Open the modal after setting values
    setModalOpen(true);
  };

  const handlePayBill = async () => {
    if (!selectedBoard) {
      toast.error("Please select an electricity board.");
      return;
    }

    const selectedData = electricityBoards.find(
      (board) => board.opcode === selectedBoard
    );

    if (!selectedData) {
      toast.error("Invalid board selected.");
      return;
    }

    const mn = formValues["mn"]; // Consumer number field key
    if (!mn) {
      toast.error("Please fill in the required consumer number.");
      return;
    }

    const url = "https://testapi.ipaisa.co.in/myapp/auth/billPay/recharge";
    const token = sessionStorage.getItem("jwtToken");

    const requestBody = {
      mn: mn,
      amt: electricityInfo?.Billamount || amount,
      opCode: selectedData.opcode,
      type: "Electricity recharge",
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok || data.status !== "SUCCESS") {
        // Show error toast with message
        toast.error(data.message || "Payment failed. Please try again.");
        return;
      }

      // Success
      toast.success("Payment successful!");
      handleClose(); // Close the modal on success
    } catch (error) {
      console.error("Payment Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };



  return (
    <Box style={{ padding: "2rem" }}>
      <Grid style={{ marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormLabel
          component="legend"
          style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
        >
          Electricity Bill Payment
        </FormLabel>
        <Typography>
          <NewBBPSIcon />
        </Typography>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '1%' }}>
        {/* Select Electricity Board */}
        <Grid item md={4} xs={12}>
          <TextField
            select
            label="Select Electricity Board"
            fullWidth
            value={selectedBoard}
            onChange={handleBoardChange}
            margin="normal"
            variant="outlined"
          >
            {electricityBoards.map((board) => (
              <MenuItem key={board.id} value={board.opcode}>
                {board.providerName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Render Dynamic Input Fields */}
        {inputFields.map((field) => (
          <Grid item md={4} xs={12} key={field.key}>
            <TextField
              label={field.label}
              fullWidth
              value={formValues[field.key] || ""}
              onChange={(e) => handleInputChange(field.key, e.target.value)}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>

      {/* Proceed Button */}
      <Box
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!electricityInfo ? (
          <Button
            style={{
              color: "white",
              backgroundColor: "#027BFE",
              textTransform: "none",
              fontSize: "16px",
              width: "16%",
            }}
            onClick={handleProceed}
            disabled={processing}
          >
            {processing ? "Processing..." : "Proceed"}
          </Button>
        ) : null}
        {electricityInfo && (
          <>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <FormLabel
                  component="legend"
                  style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
                >
                  Customer Details
                </FormLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Customer Name"
                  value={electricityInfo.CustomerName}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Bill Amount"
                  value={electricityInfo.Billamount}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Bill Date"
                  value={electricityInfo.Billdate}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Due Date"
                  value={electricityInfo.Duedate}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>

              {/* Proceed Button */}

              <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} spacing={2}>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#f44336",
                    textTransform: "none",
                    fontSize: "16px",
                    width: "12%",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#4caf50",
                    textTransform: "none",
                    fontSize: "16px",
                    width: "12%",
                  }}

                  onClick={handlePayClick}

                >
                  Pay
                </Button>
              </Grid>
            </Grid>

          </>
        )}
        {/* Payment Modal */}
        <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid style={{ marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormLabel
                component="legend"
                style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
              >
                Electricity Bill Payment
              </FormLabel>
              <Typography>
                <NewBBPSIcon />
              </Typography>
            </Grid>
            <Grid container spacing={4} sx={{ marginTop: '2%' }}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Consumer Number
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }} fontWeight="bold">
                      {consumerNumber}
                    </Typography>
                  </Grid>
                  {/* <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      BU
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }} fontWeight="bold">
                      {billingUnit}
                    </Typography>
                  </Grid> */}

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Customer Name
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }} fontWeight="bold">
                      {customerName}
                    </Typography>
                  </Grid>
                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Bill Date
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }} fontWeight="bold">
                      {billDate}
                    </Typography>
                  </Grid>
                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Due Date
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }} fontWeight="bold">
                      {dueDate}
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }} fontWeight="bold">
                      {amount}
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    onClick={handlePayBill}

                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      {Number(walletBalance).toFixed(2)}
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NewElectricity2;
