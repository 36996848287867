import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress, Grid } from '@mui/material';
import { useFormik, Formik, Form } from 'formik';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import * as Yup from 'yup';

const BulkPayoutSchema = Yup.object().shape({
    file: Yup.mixed().required("File is required"),
});

const BulkFileUpload = ({ setFieldValue }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.xlsx',
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                setFieldValue('file', file);
                setSelectedFile(file);
                setError('');
            } else if (rejectedFiles.length > 0) {
                setError('Only .xlsx files are allowed');
            }
        },
    });

    const handleRemoveFile = () => {
        setFieldValue('file', null);
        setSelectedFile(null);
        setError('');
    };

    return (
        <div>
            <div
                {...getRootProps()}
                style={{
                    border: '2px dashed #CCCCCC',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '16px',
                }}
            >
                <input {...getInputProps()} />
                <Typography>Drag and Drop an .xlsx file here or click to select a file</Typography>
            </div>
            {error && (
                <Typography color="error" variant="body2">
                    {error}
                </Typography>
            )}
            {selectedFile && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography>{selectedFile.name}</Typography>
                        <Button variant="outlined" color="error" onClick={handleRemoveFile}>
                            Remove
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
};

const BulkPayout = ({ walletBalance }) => {
    const handleBulkSubmit = async (values, { setSubmitting }) => {
        const file = values.file;

        if (!file) {
            toast.error('No file selected');
            setSubmitting(false);
            return;
        }

        try {
            const workbook = XLSX.read(await file.arrayBuffer(), { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet);

            // Convert JSON data to CSV format
            const csvString = Papa.unparse(jsonData);
            const newCsvFile = new Blob([csvString], { type: 'text/csv' });
            const formData = new FormData();
            formData.append('file', newCsvFile, 'processed_payout.csv');

            const token = sessionStorage.getItem('jwtToken');
            const response = await fetch('https://testapi.ipaisa.co.in/myapp/auth/launchjob', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const totalAmount = jsonData.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
            if (totalAmount > walletBalance) {
                throw new Error('Insufficient wallet balance');
            }

            if (!response.ok) {
                throw new Error('Failed to submit bulk payout');
            }

            toast.success('Bulk Payout successful');
        } catch (error) {
            console.error('Bulk Payout failed:', error);
            toast.error('Bulk Payout failed: ' + error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Bulk Payout
            </Typography>
            <Formik
                initialValues={{ file: null }}
                onSubmit={handleBulkSubmit}
                validationSchema={BulkPayoutSchema}
            >
                {({ isSubmitting, setFieldValue, errors, touched }) => (
                    <Form>
                        <BulkFileUpload setFieldValue={setFieldValue} />
                        {errors.file && touched.file && (
                            <Typography color="error" variant="body2">
                                {errors.file}
                            </Typography>
                        )}
                        <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    width: { xs: '100%', md: 'auto' },
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                }}
                                component="a"
                                href={`${process.env.PUBLIC_URL}/payoutsheet.xlsx`}
                                download="sample.xlsx"
                            >
                                Sample CSV
                            </Button>
                            <Button
                                sx={{
                                    border: '1.5px solid #FF0000',
                                    color: '#FF0000',
                                    textTransform: 'none',
                                    width: { xs: '100%', md: '18%' },
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: "#027BFE",
                                    color: "white",
                                    textTransform: "none",
                                    width: { xs: '100%', md: '18%' },
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        backgroundColor: "#027BFE",
                                        color: '#FFFFFF',
                                    },
                                }}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Bulk Payout'}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default BulkPayout;
