import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
  Popover,
  Divider,
  FormGroup,
  Checkbox,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import flightHome from "../Assets/Services/Flight/flightHome.svg";
import FlightIcon from "@mui/icons-material/Flight";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import axios from "axios";

function Flights1() {
  const [tripType, setTripType] = useState("oneWay");
  const [anchorEl, setAnchorEl] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [classType, setClassType] = useState(2);
  const [flights, setFlights] = useState([
    { id: 1, from: "", to: "", depart: "" },
  ]);
  const [directFlight, setDirectFlight] = useState(false);
  const [oneStopFlight, setOneStopFlight] = useState(false);
  const [resultFareType, setResultFareType] = useState("2");

  const [loading, setLoading] = useState(false);

  const handleFlightSearch = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      toast.error("Authentication token missing. Please log in.");
      return;
    }
    // Modify the segments based on trip type
    let segments = [];
    if (tripType === "oneWay") {
      segments = flights.map((flight) => ({
        Origin: flight.from,
        Destination: flight.to,
        FlightCabinClass: classType.toString(),
        PreferredDepartureTime: `${flight.depart}T00:00:00`,
        PreferredArrivalTime: `${flight.depart}T00:00:00`,
      }));
    } else if (tripType === "roundTrip") {
      // Include both outbound and return segments for round trip
      segments = [
        {
          Origin: flights[0]?.from,
          Destination: flights[0]?.to,
          FlightCabinClass: classType.toString(),
          PreferredDepartureTime: `${flights[0]?.depart}T00:00:00`,
          PreferredArrivalTime: `${flights[0]?.depart}T00:00:00`,
        },
        {
          Origin: flights[0]?.to,
          Destination: flights[0]?.from,
          FlightCabinClass: classType.toString(),
          PreferredDepartureTime: `${flights[0]?.return}T00:00:00`,
          PreferredArrivalTime: `${flights[0]?.return}T00:00:00`,
        },
      ];
    } else if (tripType === "multiCity") {
      segments = flights.map((flight) => ({
        Origin: flight.from,
        Destination: flight.to,
        FlightCabinClass: classType.toString(),
        PreferredDepartureTime: `${flight.depart}T00:00:00`,
        PreferredArrivalTime: `${flight.depart}T00:00:00`,
      }));
    }
    const searchDetails = {
      from: flights[0]?.from || "",
      to: flights[0]?.to || "",
      departDate: flights[0]?.depart || "",
      travelers: adults + children + infants || "",
      travelClass: classTypeNames[classType],
      adults: adults,
      children: children,
      infants: infants,
    };
    const payload = {
      AdultCount: adults.toString(),
      ChildCount: children.toString(),
      InfantCount: infants.toString(),
      DirectFlight: directFlight.toString(),
      OneStopFlight: oneStopFlight.toString(),
      JourneyType:
        tripType === "oneWay" ? "1" : tripType === "roundTrip" ? "2" : "3",
      ResultFareType: resultFareType,
      PreferredAirlines: null,
      Segments: segments, // Dynamic segments for round-trip, one-way, or multi-city
      Sources: null,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/flight/search",
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.Response?.Error?.ErrorCode === 0) {
        // console.log("Flight search response:", response.data);
        toast.success("Flights retrieved successfully!");
        const flightsData = response.data;
        if (tripType === "oneWay") {
          navigate("/one-way-flights-details", {
            state: { flightsData, searchDetails },
          });
        } else if (tripType === "roundTrip") {
          navigate("/round-trip-flights-details", {
            state: { flightsData, searchDetails },
          });
        } else if (tripType === "multiCity") {
          navigate("/multi-city-flights-details", { state: flightsData });
        }
      } else {
        toast.warn(
          response.data.Response.Error.ErrorMessage ||
            "Something went wrong, Please try again!"
        );
      }
    } catch (error) {
      console.error("Error fetching flights:", error);
      // console.log("Error...", error.response);
      toast.error(
        "Failed to fetch flight data. Please try again after sometime."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleIncrement = (setFunction, value) => {
    setFunction(value + 1);
  };

  const handleDecrement = (setFunction, value) => {
    if (value > 0) setFunction(value - 1);
  };

  const navigate = useNavigate();

  // Update trip type based on selection
  const handleTripTypeChange = (event) => {
    const selectedTripType = event.target.value;
    setTripType(selectedTripType);

    // Reset the flights based on trip type
    if (selectedTripType === "oneWay") {
      setFlights([{ id: 1, from: "", to: "", depart: "" }]);
    } else if (selectedTripType === "roundTrip") {
      setFlights([{ id: 1, from: "", to: "", depart: "", return: "" }]);
    } else if (selectedTripType === "multiCity") {
      setFlights([{ id: 1, from: "", to: "", depart: "" }]);
    }
  };

  const handleAddFlight = () => {
    if (flights.length < 6) {
      setFlights([
        ...flights,
        { id: flights.length + 1, from: "", to: "", depart: "" },
      ]);
    } else {
      // alert("You can only add up to 6 flights for multi-city trips.");
      toast.warn("You can only add up to 6 flights for multi-city trips.");
    }
  };

  const handleRemoveFlight = (id) => {
    setFlights(flights.filter((flight) => flight.id !== id));
  };

  const handleFlightChange = (id, field, value) => {
    setFlights(
      flights.map((flight) =>
        flight.id === id ? { ...flight, [field]: value } : flight
      )
    );
  };

  const classTypeNames = {
    1: "All",
    2: "Economy",
    3: "Premium Economy",
    4: "Business",
    5: "Premium Business",
    6: "First Class",
  };

  const styles = {
    header: {
      color: "#343C6A",
      fontSize: "22px",
      fontWeight: 700,
      fontFamily: "Open Sans",
      mt: 2,
    },
    searchPaper: {
      p: 3,
      borderRadius: "20px",
      position: "absolute", // Ensure it stays in a fixed reference point
      top: "75%", // Center it initially in its container
      left: "50%",
      transform: "translate(-50%, 0)", // Adjust to remove upward movement
      width: "90%", // Maintain consistent width
      display: "flex",
      flexDirection: "column",
      boxShadow: 3,
      overflow: "visible", // Allow the content to expand downward
    },
    textField: {
      width: { xs: "100%", md: "100%" },
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
      mt: 1,
    },
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: "8%", mb: "50%" }}>
      <Typography>Service &gt; Flight Booking</Typography>

      <Typography sx={styles.header}>Flight Booking</Typography>
      <Box sx={{ mt: "2%", position: "relative" }}>
        <img
          src={flightHome}
          alt="flightHome"
          style={{ width: "100%", position: "relative" }}
        />

        <Paper sx={styles.searchPaper}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Open Sans",
            }}
          >
            Where are you flying?
          </Typography>

          <FormControl sx={{ mt: 1 }}>
            <RadioGroup row value={tripType} onChange={handleTripTypeChange}>
              <FormControlLabel
                value="oneWay"
                control={<Radio />}
                label="One Way"
              />
              <FormControlLabel
                value="roundTrip"
                control={<Radio />}
                label="Round Trip"
              />
              <FormControlLabel
                value="multiCity"
                control={<Radio />}
                label="Multi City"
              />
            </RadioGroup>
          </FormControl>

          <Grid container spacing={2}>
            <Grid item md={7} spacing={2}>
              {flights.map((flight, index) => (
                <Grid container spacing={2} key={flight.id}>
                  {/* From */}
                  <Grid item xs={12} md={3}>
                    <TextField
                      select
                      fullWidth
                      label="From"
                      value={flight.from}
                      onChange={(e) =>
                        handleFlightChange(
                          flight.id,
                          "from",
                          e.target.value.toUpperCase()
                        )
                      }
                      sx={styles.textField}
                      InputLabelProps={{ shrink: true }}
                      placeholder="City Code"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="DEL">Delhi - DEL</MenuItem>
                      <MenuItem value="BOM">Mumbai - BOM</MenuItem>
                      <MenuItem value="DXB">Dubai - DXB</MenuItem>
                      <MenuItem value="PNQ">Pune - PNQ</MenuItem>
                      <MenuItem value="BKK">Bangkok - BKK</MenuItem>
                    </TextField>
                  </Grid>

                  {/* <SwapHorizIcon sx={{ mt: 3 }} /> */}

                  {/* To */}
                  <Grid item xs={12} md={3}>
                    <TextField
                      select
                      fullWidth
                      label="To"
                      value={flight.to}
                      onChange={(e) =>
                        handleFlightChange(
                          flight.id,
                          "to",
                          e.target.value.toUpperCase()
                        )
                      }
                      sx={styles.textField}
                      InputLabelProps={{ shrink: true }}
                      placeholder="City Code"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="DEL">Delhi - DEL</MenuItem>
                      <MenuItem value="BOM">Mumbai - BOM</MenuItem>
                      <MenuItem value="DXB">Dubai - DXB</MenuItem>
                      <MenuItem value="PNQ">Pune - PNQ</MenuItem>
                      <MenuItem value="BKK">Bangkok - BKK</MenuItem>
                    </TextField>
                  </Grid>

                  {/* Depart */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Depart"
                      value={flight.depart}
                      onChange={(e) =>
                        handleFlightChange(flight.id, "depart", e.target.value)
                      }
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      sx={styles.textField}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  {/* CloseIcon */}
                  {index > 0 && (
                    <Grid item xs={12} md={1}>
                      <IconButton
                        sx={{ mt: 1 }}
                        onClick={() => handleRemoveFlight(flight.id)}
                        color="error"
                      >
                        <CloseIcon sx={{ fontWeight: 900 }} />
                      </IconButton>
                    </Grid>
                  )}

                  {/* Return */}
                  {tripType === "roundTrip" && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        type="date"
                        label="Return"
                        value={flights[0]?.return || ""}
                        onChange={(e) =>
                          handleFlightChange(
                            flights[0]?.id,
                            "return",
                            e.target.value
                          )
                        }
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                        sx={styles.textField}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
            {/* Travelers Grid */}
            <Grid item md={5} mt={2}>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {/* Travelers */}
                <TextField
                  fullWidth
                  label="Travelers"
                  value={`${adults} ${
                    adults === 1 ? "Adult" : "Adults"
                  }, ${children} ${
                    children === 1 ? "Child" : "Children"
                  }, ${infants} ${infants === 1 ? "Infant" : "Infants"}, ${
                    classTypeNames[classType]
                  }`}
                  onClick={handleOpen}
                  sx={styles.textField}
                  InputProps={{
                    endAdornment: <ArrowDropDownIcon />,
                    readOnly: true,
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box style={{ padding: "16px", width: "250px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontFamily: "Open Sans",
                            mt: 1,
                          }}
                        >
                          Adults ( {adults} )
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDecrement(setAdults, adults)}
                        >
                          <RemoveIcon sx={{ color: "red" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleIncrement(setAdults, adults)}
                        >
                          <AddIcon sx={{ color: "green" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", fontFamily: "Open Sans" }}
                        >
                          Children ( {children} )
                        </Typography>
                        <Typography sx={{ fontFamily: "Open Sans" }}>
                          (2-12 Years)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDecrement(setChildren, children)}
                        >
                          <RemoveIcon sx={{ color: "red" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleIncrement(setChildren, children)}
                        >
                          <AddIcon sx={{ color: "green" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", fontFamily: "Open Sans" }}
                        >
                          Infants ( {infants} )
                        </Typography>
                        <Typography sx={{ fontFamily: "Open Sans" }}>
                          (0-23 Months)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDecrement(setInfants, infants)}
                        >
                          <RemoveIcon sx={{ color: "red" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleIncrement(setInfants, infants)}
                        >
                          <AddIcon sx={{ color: "green" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <RadioGroup
                      value={classType}
                      onChange={(e) => setClassType(e.target.value)}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Economy"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Premium Economy"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="Business"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="Premium Business"
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label="First Class"
                      />
                    </RadioGroup>
                    <Button
                      onClick={handleClose}
                      sx={{
                        background: "#027BFE",
                        color: "white",
                        textTransform: "none",
                        mt: 1,
                        width: "50%",
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Popover>
              </Box>
            </Grid>
          </Grid>

          {/* Buttons */}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
          >
            {tripType === "multiCity" && (
              <Button
                variant="outlined"
                sx={{ textTransform: "none", borderRadius: "10px" }}
                onClick={handleAddFlight}
                // disabled={flights.length >= 6}
              >
                + Add Another Flight
              </Button>
            )}

            <Button
              startIcon={<FlightIcon sx={{ color: "white" }} />}
              sx={{
                background: "#027BFE",
                color: "white",
                textTransform: "none",
                px: 2,
                width: "150px",
                borderRadius: "10px",
              }}
              onClick={handleFlightSearch}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "Show Flights"
              )}
            </Button>
          </Box>

          <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel control={<Checkbox />} label="Direct Flight" />
            <FormControlLabel control={<Checkbox />} label="One Stop Flight" />
          </FormGroup>
          <Box></Box>

          <Divider sx={{ mt: 1 }} />

          <FormControl sx={{ mt: 1 }}>
            <RadioGroup row name="discount-type" defaultValue="regular">
              <FormControlLabel
                value="regular"
                control={<Radio />}
                label="Regular"
              />
              <FormControlLabel
                value="student"
                control={<Radio />}
                label="Student"
              />
              <FormControlLabel
                value="seniorCitizen"
                control={<Radio />}
                label="Senior Citizen"
              />
              <FormControlLabel
                value="armedForces"
                control={<Radio />}
                label="Armed Forces"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
      </Box>
    </Container>
  );
}

export default Flights1;
