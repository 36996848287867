import React, { useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  CircularProgress,
  TablePagination,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import SearchAndFilters from "./SearchAndFilters";
import TransactionTable from "./TransactionTable";
import axios from "axios";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import { DownloadIcon, PartnerArrowIcon } from "../../../res/NewWebIcons";

const HierarchyReport = () => {
  const [tabValue, setTabValue] = useState("Pay-In");
  const [payInRecords, setPayInRecords] = useState([]);
  const [payOutRecords, setPayOutRecords] = useState([]);
  const [ftrRecords, setFtrRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    userType: "",
    selectedUserId: null,
    searchTerm: "",
    fromDate: "",
    toDate: "",
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState("CREDIT"); // Default to "Credit"
  // Separate states for FTR pagination
  const [ftrPage, setFtrPage] = useState(0);
  const [ftrSize, setFtrSize] = useState(10);
  const [totalFtrRecords, setTotalFtrRecords] = useState(0);

  const handleTabClick = (transactionType) => {
    setActiveTab(transactionType);
    handleSearch(searchParams, page, size, transactionType);
  };
  const handleSearch = async (
    params,
    page = 0,
    size = 10,
    transactionType = activeTab
  ) => {
    setLoading(true);
    const { userType, selectedUserId, searchTerm, fromDate, toDate } = params;
    const jwtToken = sessionStorage.getItem("jwtToken");
    let apiEndpoint = "";

    // Adjust size if the active tab is 'FTR'
    if (tabValue === "FTR") {
      size = 20;
    }

    const queryParams = {
      searchTerm,
      fromDate,
      toDate,
      userId: selectedUserId,
      page,
      size,
    };

    if (tabValue === "Pay-In") {
      apiEndpoint =
        "https://testapi.ipaisa.co.in/myapp/admin/getAllPyInTxnsByUserId";
    } else if (tabValue === "Pay-Out") {
      apiEndpoint =
        "https://testapi.ipaisa.co.in/myapp/admin/getAllPayoutTxnsByUserId";
    } else if (tabValue === "FTR") {
      apiEndpoint =
        "https://testapi.ipaisa.co.in/myapp/admin/hierarchyFtrReport";
      queryParams.transactionType = transactionType; // Add transactionType
    }

    try {
      const response = await axios.get(apiEndpoint, {
        headers: { Authorization: `Bearer ${jwtToken}` },
        params: queryParams,
      });

      if (response.data.statusCodeValue === 200) {
        const total = response.data.body.totalRecords;
        const currentPage = response.data.body.currentPage;
        const totalPages = response.data.body.totalPages;

        setTotalRecords(total);
        setPage(currentPage);
        setTotalPages(totalPages);
        setCurrentPage(currentPage);

        if (tabValue === "Pay-In") {
          const username = response.data.body.sender_address.username;
          const userAddress = response.data.body.sender_address.usersAddress;

          const records = response.data.body.data.map((item) => {
            // const date = new Date(item.addedon);
            // const options = {
            //   timeZone: "Asia/Kolkata",
            //   year: "numeric",
            //   month: "long",
            //   day: "numeric",
            //   hour: "2-digit",
            //   minute: "2-digit",
            //   second: "2-digit",
            // };

            return {
              id: item.id,
              txnid: item.txnid,
              easepayid: item.easepayid,
              cardType: item.cardType,
              upiVa: item.upiVa,
              cardnum: item.cardnum,
              phone: item.phone,
              addedon: item.addedon,
              netAmountDebit: item.netAmountDebit,
              amount: item.amount,
              status: item.status,
              userId: item.userId,
              mode: item.mode,
              settlement: item.settlement,
              name: username,
              userAddress: userAddress,
            };
          });

          setPayInRecords(records);
        } else if (tabValue === "Pay-Out") {
          const { transaction, sender_address } = response.data.body.data;
          const records =
            transaction?.map((item) => ({
              ...item,
              sender_address: sender_address?.usersAddress || null,
              sender_name: sender_address?.username || null,
            })) || [];
          setPayOutRecords(records);
        } else if (tabValue === "FTR") {
          const records =
            response.data.body.data.map((item) => ({
              id: item.id,
              transactionId: item.transactionId,
              amount: item.amount,
              remark: item.remark,
              date: item.date,
              utype: item.utype,
              wOpeningBlc: item.wOpeningBlc,
              wClosingBlc: item.wClosingBlc,
              transactionType: item.transactionType,
              reciver: item.reciver,
              receiverUserName: item.receiverUserName,
              sender: item.sender,
              senderUserName: item.senderUserName,
            })) || [];
          setFtrRecords(records);
          setTotalFtrRecords(response.data.body.totalRecords);
        } else {
          console.error("Failed to fetch data");
        }
      }
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handlers for FTR pagination
  const handleFtrPageChange = (event, newPage) => {
    setFtrPage(newPage);
    handleSearch(searchParams, newPage, ftrSize); // Ensure you pass ftrSize for FTR specific pagination
  };

  const handleFtrRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setFtrSize(newSize);
    setFtrPage(1); // Reset to the first page
    handleSearch(searchParams, 0, newSize);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPayInRecords([]);
    setPayOutRecords([]);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleSearch(searchParams, newPage, size);
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setSize(newSize);
    setPage(0);
    handleSearch(searchParams, 0, newSize);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    let tableColumn = [];
    const tableRows = [];

    let records = [];
    if (tabValue === "Pay-In") {
      tableColumn = [
        "Transaction ID",
        "User ID",
        "Date",
        "PayIn Amount",
        "Amount",
        "Status",
        "Settlement",
      ];
      records = payInRecords;
      records.forEach((record) => {
        const data = [
          record.txnid,
          record.userId,
          new Date(record.addedon).toLocaleString(),
          Number(record.netAmountDebit).toFixed(2),
          Number(record.amount).toFixed(2),
          record.status,
          record.settlement,
          record.userId,
        ];
        tableRows.push(data);
      });
    } else if (tabValue === "Pay-Out") {
      tableColumn = [
        "External Ref",
        "Payee Account",
        "Payee Name",
        "Date",
        "Wallet Opening Balance",
        "Txn.Value",
        "Wallet Closing Balance",
        "Transfer Mode",
        "Status",
      ];
      records = payOutRecords;
      records.forEach((record) => {
        const data = [
          record.externalRef,
          record.payeeAccount,
          record.payeeName,
          new Date(record.timestamp).toLocaleString(),
          record.walletOpeningBalance,
          Number(record.txnValue).toFixed(2),
          record.walletClosingBalance,
          record.transfer_mode,
          record.status,
        ];
        tableRows.push(data);
      });
    } else if (tabValue === "FTR") {
      tableColumn = [
        "Transaction ID",
        "Sender",
        "Receiver",
        "Transaction Type",
        "Amount",
        "Date",
        "Opening Balance",
        "Closing Balance",
        "Remark",
      ];
      records = ftrRecords;
      records.forEach((record) => {
        const data = [
          record.transactionId,
          record.sender,
          record.reciver,
          record.transactionType,
          Number(record.amount).toFixed(2),
          new Date(record.date).toLocaleString(),
          Number(record.wOpeningBlc).toFixed(2),
          Number(record.wClosingBlc).toFixed(2),
          record.remark,
        ];
        tableRows.push(data);
      });
    } else {
      console.error("Invalid tabValue or no records available");
      return;
    }

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(`${tabValue}_Transactions.pdf`);
  };

  const downloadCSV = () => {
    let csvContent = "";
    const records = tabValue === "Pay-In" 
    ? payInRecords 
    : tabValue === "Pay-Out" 
      ? payOutRecords 
      : ftrRecords;
  
    // Set column headers based on the transaction type
    if (tabValue === "Pay-In") {
      csvContent +=
        "Transaction ID,User ID,Phone,Mode,Date,Time,PayIn Amount,Amount,Status,Settlement\n";
      records.forEach((record) => {
        csvContent += `${record.txnid},${record.userId},${record.phone},${record.mode},${new Date(record.addedon).toLocaleString()},${Number(record.netAmountDebit).toFixed(2)},${Number(record.amount).toFixed(2)},${record.status},${record.settlement}\n`;
      });
    } else if (tabValue === "Pay-Out") {
      csvContent +=
        "External Ref,Payer UID,Payer Name,Payee Account,Payee Name,Date,Time,Order ID,Wallet Opening Balance,Txn.Value,Wallet Closing Balance,Transfer Mode,Provider Name,Status\n";
      records.forEach((record) => {
        csvContent += `${record.externalRef},${record.userdetails.userid},${record.userdetails.name},${record.payeeAccount},${record.payeeName},${new Date(record.timestamp).toLocaleString()},${record.orderid},${Number(record.walletOpeningBalance).toFixed(2)},${record.txnValue},${Number(record.walletClosingBalance).toFixed(2)},${record.transfer_mode},${record.providerName},${record.status},\n`;
      });
    } else {
      csvContent +=
      "transactionId ,sender,senderUserName,reciver,receiverUserName,Date,Time,transactionType,amount,remark,\n";
    records.forEach((record) => {
      csvContent += `${record.transactionId},${record.sender},${record.senderUserName},${record.reciver},${record.receiverUserName},${new Date(record.date).toLocaleString()},${record.transactionType},${Number(record.amount).toFixed(2)},${record.remark},\n`;
    });
    }

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${tabValue}_Transactions.csv`);
  };

  return (
    <Box style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Reports
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Hierarchywise Report
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "1%" }}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "sans-serif",
              fontSize: { xs: "16px", md: "19px" },
              color: "#343C6A",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Hierarchywise Report
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Button
            onClick={downloadCSV} // Single handler for both DEBIT and CREDIT
            sx={{
              textTransform: "none",
              backgroundColor: "#027BFE",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
              height: "40px",
              gap: 1,
            }}
          >
            <DownloadIcon />
            Download CSV
          </Button>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#027BFE",
              color: "#FFFFFF",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
              height: "40px",
              minWidth: { xs: "120px", sm: "140px" },
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
            onClick={downloadPDF}
          >
            <DownloadIcon />
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="start" alignItems="center" mb={2}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Pay-In" value="Pay-In" />
          <Tab label="Pay-Out" value="Pay-Out" />
          <Tab label="FTR" value="FTR" />
        </Tabs>
      </Box>
      {tabValue === "FTR" && (
        <Grid
          item
          style={{
            display: "flex",
            gap: "8px",
            marginBottom: "1rem",
          }}
        >
          <Button
            onClick={() => handleTabClick("DEBIT")}
            sx={{
              textTransform: "none",
              backgroundColor: activeTab === "DEBIT" ? "red" : "gray",
              color: "white",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
            }}
          >
            Debit
          </Button>
          <Button
            onClick={() => handleTabClick("CREDIT")}
            sx={{
              textTransform: "none",
              backgroundColor: activeTab === "CREDIT" ? "green" : "gray",
              color: "white",
              borderRadius: "10px",
              padding: "8px 16px",
              fontFamily: "sans-serif",
              fontSize: { xs: "12px", md: "14px" },
            }}
          >
            Credit
          </Button>
        </Grid>
      )}

      <SearchAndFilters
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        handleSearch={(params) => handleSearch(params, page, size)}
      />

      {loading ? (
        <CircularProgress sx={{ marginTop: "2%" }} />
      ) : (
        <>
          {tabValue === "Pay-In" ? (
            <TransactionTable
              userRecords={payInRecords}
              page={page}
              rowsPerPage={size}
              transactionType="Pay-In"
            />
          ) : tabValue === "Pay-Out" ? (
            <TransactionTable
              userRecords={payOutRecords}
              page={page}
              rowsPerPage={size}
              transactionType="Pay-Out"
            />
          ) : tabValue === "FTR" ? (
            <TransactionTable
              userRecords={ftrRecords}
              page={page}
              rowsPerPage={ftrSize}
              transactionType="FTR"
            />
          ) : (
            <div>No data available</div>
          )}

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ marginTop: "1%" }}
          >
            {tabValue === "FTR" ? (
              // Custom Pagination for FTR
              <TablePagination
                component="div"
                count={totalFtrRecords} // Adjust count for FTR records
                page={ftrPage} // Different state for FTR page
                onPageChange={handleFtrPageChange} // Different handler for FTR pagination
                rowsPerPage={ftrSize} // Different rows per page for FTR
                onRowsPerPageChange={handleFtrRowsPerPageChange} // Different handler for rows per page change
                rowsPerPageOptions={[10, 25, 50]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count}`
                }
                labelRowsPerPage="Rows per page"
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                nextIconButtonProps={{
                  disabled: totalFtrRecords !== 10, // Disable Next if records are not 10
                }}
                backIconButtonProps={{
                  disabled: ftrPage === 0, // Disable Back on the first page
                }}
              />
            ) : (
              // Default Pagination for Pay-In and Pay-Out
              <TablePagination
                component="div"
                count={totalRecords}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[10, 25, 50]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${currentPage + 1} of ${totalPages + 1}`
                }
                labelRowsPerPage="Rows per page"
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                nextIconButtonProps={{
                  disabled: page >= totalPages,
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default HierarchyReport;
