import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Container,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PartnerArrowIcon } from '../../../res/NewWebIcons';

const SubAdminList = () => {
  const [subAdminList, setSubAdminList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSubAdminList = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get('https://testapi.ipaisa.co.in/myapp/auth/subadminlist', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.message === 'success') {
        setSubAdminList(response.data.list);
      } else {
        toast.error('Failed to fetch sub-admin list');
      }
    } catch (error) {
      console.error('Error fetching sub-admin list:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (subAdminId) => {
    try {
      const token = sessionStorage.getItem('jwtToken');
      await axios.get(`https://testapi.ipaisa.co.in/myapp/auth/deletepartner/${subAdminId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the list after successful deletion
      setSubAdminList((prevList) => prevList.filter((admin) => admin.userid !== subAdminId));

      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSubAdminList();
  }, []);

  return (
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <ToastContainer />
      <Grid>
        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1%',
            justifyContent: 'start',
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
          >
            Sub Admin
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
          >
            Sub Admin List
          </Typography>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1%',
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                fontSize: '20px',
                color: '#343C6A',
              }}
            >
              Sub Admin List
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{marginTop:'2%'}}>
        <TableContainer component={Paper} sx={{borderRadius:'12px 12px 0px 0px'}}>
          <Table>
            <TableHead sx={{ backgroundColor: '#027BFE' }}>
              <TableRow>
                <TableCell sx={{color:'white'}}>Sr. No.</TableCell>
                <TableCell sx={{color:'white'}}>User ID</TableCell>
                <TableCell sx={{color:'white'}}>Name</TableCell>
                <TableCell sx={{color:'white'}}>Email</TableCell>
                <TableCell sx={{color:'white'}}>Mobile Number</TableCell>
                <TableCell sx={{color:'white'}}>Created At</TableCell>
                <TableCell sx={{color:'white'}}>Delete</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                Loading...
              </Typography>
            ) : (
              <TableBody>
                {subAdminList.map((subAdmin, index) => (
                  <TableRow key={subAdmin.userid}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{subAdmin.userid}</TableCell>
                    <TableCell>
                        {`${subAdmin.firstName} ${subAdmin.middleName} ${subAdmin.lastName}`}
                    </TableCell>
                    <TableCell>{subAdmin.email}</TableCell>
                    <TableCell>{subAdmin.mobileNumber}</TableCell>
                    <TableCell>
                      {new Date(subAdmin.createdAt).toLocaleDateString()}{' '}
                      {new Date(subAdmin.createdAt).toLocaleTimeString()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(subAdmin.userid)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default SubAdminList;
