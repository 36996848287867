import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@mui/material';
import axios from "axios";

const EditUserDialog = ({ open, handleClose, user, handleSave, handlePath,onEditComplete }) => {
    const [userData, setUserData] = useState({
        userid: '',
        firstName: '',
        middleName: '',
        lastName: '',
        businessName: '',
        email: '',
        mobileNumber: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        address: '',
        dob: '',
        comissions: ''
    });

    useEffect(() => {
        if (user) {
            setUserData({
                userid: user.userid || '',
                firstName: user.firstName || '',
                middleName: user.middleName || '',
                lastName: user.lastName || '',
                businessName: user.bussinessName || '',
                email: user.email || '',
                mobileNumber: user.mobileNumber || '',
                country: user.userAddress?.country || '',
                state: user.userAddress?.state || '',
                city: user.userAddress?.city || '',
                pincode: user.userAddress?.pincode || '',
                address: user.userAddress?.address || '',
                dob: user.dob || '',
                comissions: user.percentage || ''
            });
        }
    }, [user]);



    const handleCancel = () => {
        handleClose();
        setUserData({
            userid: '',
            firstName: '',
            middleName: '',
            lastName: '',
            businessName: '',
            email: '',
            mobileNumber: '',
            address: '',
            dob: '',
            comissions: '',
        });

    };

    const handleSaveUser = async () => {
        // Simple validation
        console.log("Saving user...", userData);

        if (!userData.userid || !userData.firstName || !userData.lastName || !userData.businessName || !userData.email || !userData.address || !userData.dob) {
            alert('All fields except middle name and mobile number are required.');
            return;
        }

        // Email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(userData.email)) {
            alert('Invalid email format.');
            return;
        }

        try {
            const token = sessionStorage.getItem('jwtToken'); // Retrieve JWT token from session storage

            // Set default values for address fields if they are not changed
            const defaultAddress = {
                address: "CSIBER",
                city: "",
                state: "",
                country: "",
                pincode: "416012",
            };

            const finalAddress = {
                address: userData.address || defaultAddress.address,
                city: userData.city || defaultAddress.city,
                state: userData.state || defaultAddress.state,
                country: userData.country || defaultAddress.country,
                pincode: userData.pincode || defaultAddress.pincode,
            };

            console.log("Token:", token);
            console.log("User Data:", userData);

            // Make API call to save the edited user details
            const response = await axios.patch(
                `https://testapi.ipaisa.co.in/myapp/auth/updateUser`,
                {
                    userid: userData.userid,
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email: userData.email,
                    mobileNumber: userData.mobileNumber, // Corrected mobile number key
                    address: finalAddress,
                    businessName:userData.businessName
                    
                    
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log("API Response:", response);

            // Pass the edited user data to the parent component
            handleSave(userData);
            handleClose();
            if (typeof onEditComplete === "function") {
                onEditComplete();
            }
        } catch (error) {
            console.error("Error saving user:", error);
            alert("Failed to save user details. Please try again.");
        }
    };


    console.log(handlePath, 'success');
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle bgcolor={'#027BFE'} color={'white'}>Edit User Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="firstName"
                            label="First Name"
                            type="text"
                            fullWidth
                            value={userData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="middleName"
                            label="Middle Name"
                            type="text"
                            fullWidth
                            value={userData.middleName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            value={userData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="businessName"
                            label="Business Name"
                            type="text"
                            fullWidth
                            value={userData.businessName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="mobileNumber"
                            label="Mobile Number"
                            type="text"
                            fullWidth
                            value={userData.mobileNumber}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={userData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="comissions"
                            label="comissions"
                            type="text"
                            fullWidth
                            value={userData.comissions}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="country"
                            label="Country"
                            type="text"
                            fullWidth
                            value={userData.country}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="state"
                            label="State"
                            type="text"
                            fullWidth
                            value={userData.state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="city"
                            label="City"
                            type="text"
                            fullWidth
                            value={userData.city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="pincode"
                            label="Pincode"
                            type="text"
                            fullWidth
                            value={userData.pincode}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            value={userData.address}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="dob"
                            label="Date of Birth"
                            type="date"
                            fullWidth
                            value={userData.dob}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={handleCancel} sx={{
                    width: '150px', height: '54px', borderRadius: '12px', backgroundColor: '#FF0000', color: '#ffffff', '&:hover': { backgroundColor: '#FF0000', color: '#ffffff' },
                }}>
                    Cancel
                </Button>
                <Button onClick={handleSaveUser} sx={{
                    width: '150px', height: '54px', borderRadius: '12px', backgroundColor: '#027BFE', color: '#FFFFFF', '&:hover': {
                        backgroundColor: '#027BFE',
                        color: 'white'
                    },
                }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;
