import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, TextField, Button, Grid, Container,
    Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const CardChargesTable = () => {
    const [chargesData, setChargesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [updatedCharge, setUpdatedCharge] = useState('');
    const [newService, setNewService] = useState('');
    const [newCharge, setNewCharge] = useState('');

    useEffect(() => {
        fetchCharges();
    }, []);

    const fetchCharges = async () => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get('https://testapi.ipaisa.co.in/myapp/admin/getAll-cc-charg', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.statusCode === "OK") {
                setChargesData(response.data.body);
            } else {
                setChargesData([]);
            }
        } catch (err) {
            setError('Failed to fetch charges data');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (index, cardType) => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            await axios.put(
                'https://testapi.ipaisa.co.in/myapp/admin/update-cc-charg',
                {
                    cardType: cardType,
                    charges: updatedCharge,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const updatedData = [...chargesData];
            updatedData[index].charges = updatedCharge;
            setChargesData(updatedData);
            setEditIndex(null);
            setUpdatedCharge('');

            toast.success(`Charges for ${cardType} updated successfully!`);
        } catch (error) {
            console.error('Error updating charges:', error);
            toast.error(`Failed to update charges for ${cardType}. Please try again.`);
        }
    };

    const handleAddCharge = async () => {
        if (!newService || !newCharge) {
            toast.error('Please provide both service and charges.');
            return;
        }

        try {
            const token = sessionStorage.getItem('jwtToken');
            await axios.post(
                'https://testapi.ipaisa.co.in/myapp/admin/setccCharges',
                {
                    cardType: newService,
                    charges: newCharge,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(`New charge added for service: ${newService}`);
            setNewService('');
            setNewCharge('');
            fetchCharges();
        } catch (error) {
            console.error('Error adding new charge:', error);
            toast.error('Failed to add new charge. Please try again.');
        }
    };

    const handleDelete = async (cardType) => {
        if (!window.confirm('Are you sure you want to delete this charge?')) {
            return;
        }

        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.delete(
                'https://testapi.ipaisa.co.in/myapp/admin/delete-cc-charg',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    data: { cardType }
                }
            );

            if (response.data.statusCode === "NO_CONTENT") {
                toast.success('Charge deleted successfully!');
                fetchCharges(); // Refresh the data after deletion
            } else {
                toast.error('Failed to delete charge. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting charge:', error);
            toast.error('Failed to delete charge. Please try again.');
        }
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Balance</Typography>
                    <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Card Charges</Typography>
            </Grid>
            <Grid style={{marginTop:'1%'}}>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Card Charges</Typography>
            </Grid>
            <Grid container spacing={3} style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                        <Table>
                            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} align="start">Service</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Charges</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {chargesData.map((charge, index) => (
                                    <TableRow key={charge.id} style={{ backgroundColor: index % 2 === 1 ? '#D0EFFF' : '#fff' }}>
                                        <TableCell>{charge.cardType}</TableCell>
                                        <TableCell>
                                            {editIndex === index ? (
                                                <TextField
                                                    type="number"
                                                    value={updatedCharge}
                                                    onChange={(e) => setUpdatedCharge(e.target.value)}
                                                    size="small"
                                                />
                                            ) : (
                                                charge.charges
                                            )}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {editIndex === index ? (
                                                <IconButton onClick={() => handleSave(index, charge.cardType)}>
                                                    <SaveIcon />
                                                </IconButton>
                                            ) : (
                                                <>
                                                    <IconButton onClick={() => { setEditIndex(index); setUpdatedCharge(charge.charges); }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDelete(charge.cardType)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Service"
                        value={newService}
                        onChange={(e) => setNewService(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Charges"
                        type="number"
                        value={newCharge}
                        onChange={(e) => setNewCharge(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleAddCharge}>
                        Add Charges
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CardChargesTable;
