import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Container,
  Box,
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PartnerArrowIcon } from '../../../res/NewWebIcons';

const UpdateSubAdmin = () => {
  const [subAdminList, setSubAdminList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [userAccess, setUserAccess] = useState({
    qqr: false,
    kqr: false,
    cc: false,
    ctr: false,
    rmr: false,
    cr: false,
    hwr: false,
    pr: false,
    tpayouts: false,
  });

  // Fetch Sub-Admin List for Dropdown
  const fetchSubAdminList = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get('https://testapi.ipaisa.co.in/myapp/auth/subadminlistfordropdown', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data?.message === 'success') {
        setSubAdminList(response.data.list);
      } else {
        toast.error('Failed to fetch sub-admin list');
      }
    } catch (error) {
      console.error('Error fetching sub-admin list:', error);
    }
  };

  // Fetch User Access Details
  const fetchUserAccess = async (userId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.get('https://testapi.ipaisa.co.in/myapp/auth/useraccess', {
        headers: {
          Authorization: `Bearer ${token}`,
          userId,
        },
      });
          // Exclude fields like 'id' and 'user'
      const { id, user, ...accessData } = response.data;
      setUserAccess(accessData);
    } catch (error) {
      console.error('Error fetching user access:', error);
      toast.error('Error fetching user access details');
    }
  };

  // Handle Update API Call
  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem('token');
      await axios.post(
        'https://testapi.ipaisa.co.in/myapp/auth/updateSubadmin',
        {
          ...userAccess,
          userId: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Sub-admin updated successfully');
    } catch (error) {
      console.error('Error updating sub-admin:', error);
      toast.error('Error updating sub-admin');
    }
  };

  // Reset Form
  const handleCancel = () => {
    setSelectedUser('');
    setUserAccess({
      qqr: false,
      kqr: false,
      cc: false,
      ctr: false,
      rmr: false,
      cr: false,
      hwr: false,
      pr: false,
      tpayouts: false,
    });
  };

  useEffect(() => {
    fetchSubAdminList();
  }, []);

  const checkboxLabels = {
    qqr: "Queen QR Report",
    kqr: "King QR Report",
    cc: "Credit Card Report",
    ctr: "Completed Transaction Report",
    rmr: "Reverse Money Report",
    cr: "Commission Report",
    hwr: "Hierarchy Wise Report",
    pr: "Pending Report",
    tpayouts: "Total Payout Report",
  };

  return (
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1%',
              justifyContent: 'start',
            }}
          >
            <Typography
              color="textSecondary"
              style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
            >
              Sub Admin
            </Typography>
            <PartnerArrowIcon />
            <Typography
              color="textSecondary"
              style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
            >
              Update Sub-Admin
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '1%',
            }}
          >
            <Grid>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'sans-serif',
                  fontSize: '20px',
                  color: '#343C6A',
                }}
              >
                Update Sub-Admin
              </Typography>
            </Grid>
          </Grid>
        </Grid>
<Grid container spacing={3} sx={{marginTop:"2%"}}>
  <ToastContainer />
  <Grid item xs={12} sm={6}>
    <Select
      fullWidth
      value={selectedUser}
      onChange={(e) => setSelectedUser(e.target.value)}
      displayEmpty
    >
      <MenuItem value="" disabled>
        Select Sub-Admin
      </MenuItem>
      {subAdminList.map((subAdmin) => (
        <MenuItem key={subAdmin.userId} value={subAdmin.userId}>
          {subAdmin.username}
        </MenuItem>
      ))}
    </Select>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Button
      sx={{
        color: 'white',
        backgroundColor: '#027BFE',
      }}
      fullWidth
      onClick={() => fetchUserAccess(selectedUser)}
      disabled={!selectedUser}
    >
      Search
    </Button>
  </Grid>
  <Grid item xs={6} sm={3}>
    <Button variant="outlined" color="gray" sx={{textTransform:'none'}} fullWidth onClick={handleCancel}>
      Clear
    </Button>
  </Grid>
  
<Grid item xs={12}>
<FormGroup>
  <Grid container spacing={2}>
    {Object.keys(userAccess).map((key) => (
      <Grid item xs={12} sm={6} key={key}>
        <FormControlLabel
          control={
            <Checkbox
              checked={userAccess[key]}
              onChange={(e) =>
                setUserAccess((prev) => ({ ...prev, [key]: e.target.checked }))
              }
            />
          }
          label={checkboxLabels[key] || key.toUpperCase()} // Use custom label or fallback to key
        />
      </Grid>
    ))}
  </Grid>
</FormGroup>
</Grid>
  <Grid item xs={6}>
    <Button 
      sx={{
        border: '1.5px solid #FF0000',
        color: '#FF0000',
        textTransform: 'none',
        height: '46px',
        fontSize: '18px',
      }}
    fullWidth 
    onClick={handleCancel}>
      Cancel
    </Button>
  </Grid>
  <Grid item xs={6}>
    <Button
     sx={{
      color: 'white',
      backgroundColor: '#027BFE',
      textTransform: 'none',
      height: '46px',
      fontSize: '18px',
    }}
    fullWidth
     onClick={handleUpdate}>
      Update
    </Button>
  </Grid>
</Grid>
</Box>

  );
};

export default UpdateSubAdmin;
