import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography } from '@material-ui/core';
import axios from 'axios';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const PayoutHierarchywise = () => {
  const [data, setData] = useState([]);
  const userId = 'AD2131'; // Example userId
  const fromDate = '2024-10-01'; // Example fromDate
  const toDate = '2024-10-31'; // Example toDate

  useEffect(() => {
    const token = sessionStorage.getItem('jwtToken');

    // Fetch data from the new API endpoint with query parameters
    axios.get(`https://testapi.ipaisa.co.in/myapp/admin/getAllPayoutTxnsByUserId?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        if (response.data.statusCodeValue === 200) {
          setData(response.data.body.data);
        } else {
          console.error("Failed to fetch data: ", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching payout data:", error);
      });
  }, []);

  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>  
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reports</Typography>
        <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Hierarchywise Payout</Typography>
      </Grid>
      <Grid style={{marginTop:'1%'}}>
        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Hierarchywise Payout</Typography>
      </Grid>
      <Grid style={{marginTop:'1%'}}>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ borderRadius: '12px 12px 0px 0px' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                <TableRow>
                  <TableCell style={{ color: 'white' }}>User ID</TableCell>
                  <TableCell style={{ color: 'white' }}>Status</TableCell>
                  <TableCell style={{ color: 'white' }}>Transaction Value</TableCell>
                  <TableCell style={{ color: 'white' }}>Payer Account</TableCell>
                  <TableCell style={{ color: 'white' }}>Payee Account</TableCell>
                  <TableCell style={{ color: 'white' }}>Payee Name</TableCell>
                  <TableCell style={{ color: 'white' }}>Timestamp</TableCell>
                  <TableCell style={{ color: 'white' }}>Transfer Mode</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.txnValue}</TableCell>
                    <TableCell>{item.payerAccount}</TableCell>
                    <TableCell>{item.payeeAccount}</TableCell>
                    <TableCell>{item.payeeName}</TableCell>
                    <TableCell>{new Date(item.timestamp).toLocaleString()}</TableCell>
                    <TableCell>{item.transfer_mode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PayoutHierarchywise;
