import React, { useState } from 'react';
import { Box, Button, Container, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Use Dayjs as the date library
import { NewBBPSIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import axios from 'axios';

const RegisterComplaint = () => {
  const [complaintType, setComplaintType] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [complaintReason, setComplaintReason] = useState('');
  const [complaintDescription, setComplaintDescription] = useState('');

  // State to hold the API response data
  const [complaintData, setComplaintData] = useState({
    billDetails: [],
    billerResponse: {
      customerName: '',
      amount: '',
      dueDate: '',
      billDate: '',
      billNumber: '',
      billPeriod: '',
      billTags: []
    },
    additionalInfo: []
  });

  // State for loading and error/success messages
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Complaint types array
  const complaintTypes = ['Landline', 'Electricity', 'Water', 'Gas'];

  // Complaint reasons array
  const complaintReasons = ['Wrong Bill Amount', 'Service Not Working', 'Payment Failed', 'Others'];

  // Handler for View button
  const handleViewClick = async () => {
    if (!complaintType || !mobileNumber || !transactionID) {
      return; // Ensure required fields are filled
    }

    try {
      // Make API call to fetch transaction details
      const response = await axios.get("https://testapi.ipaisa.co.in/myapp/auth/axisobj", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          complaintType,
          mobileNumber,
          transactionID,
          fromDate,
          toDate,
        }
      });

      // Update the state with the API response data
      setComplaintData(response.data.response);

      // Display the additional details section
      setShowDetails(true);
    } catch (error) {
      console.error('Error fetching complaint details:', error);
      // Handle error (e.g., show a notification or alert)
    }
  };

  // Function to handle complaint registration
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const token = sessionStorage.getItem("jwtToken");

      const response = await axios.post(
        'http://192.168.31.163:5001/myapp/auth/bbps/complaintRegister',
        {
          complaintType,
          mobileNumber,
          transactionID,
          complaintReason,
          complaintDescription
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          timeout: 300000, // Set timeout to 5 minutes (300,000 milliseconds)
        }
      );

      if (response.status === 504) {
        setSuccessMessage('Complaint Registered Successfully!');

        // Fetch transaction response after 3 minutes
        setTimeout(() => {
          // fetchTransactionResponse();
        }, 3 * 60 * 1000); // 3 minutes in milliseconds
      }
    } catch (err) {
      if (err.response) {
        setError(`Failed to register complaint: ${err.response.data.message || 'Please try again.'}`);
      } else if (err.request) {
        setError('No response received from server. Please check your connection.');
      } else {
        setError('Error: ' + err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid>
          {/* Header */}
          <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
              Complaint Management
            </Typography>
            <PartnerArrowIcon />
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
              Register Complaint
            </Typography>
          </Grid>

          <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
              Register Complaint
            </Typography>
            <Typography>
              <NewBBPSIcon/>
            </Typography>
          </Grid>

          {/* Complaint Form */}
          <Paper elevation={2} style={{ padding: '2rem', marginTop: '16px' }}>
            <Grid container spacing={2} style={{ marginTop: '2%' }}>
              {/* First row: Type of Complaint, Mobile Number, Transaction ID */}
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Type of Complaint"
                  value={complaintType}
                  onChange={(e) => setComplaintType(e.target.value)}
                  fullWidth
                  variant="outlined"
                >
                  {complaintTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Transaction ID"
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              {/* Second row: From Date, To Date */}
              <Grid item xs={12} md={3}>
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  onChange={(date) => setFromDate(date)}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  label="To Date"
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </Grid>

              {/* View Button */}
              <Grid container item justifyContent="flex-end" style={{ marginTop: '2%' }}>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    style={{ color: 'white', backgroundColor: '#027BFE', textTransform: 'none' }}
                    onClick={handleViewClick}
                    disabled={!complaintType || !mobileNumber || !transactionID}
                  >
                    View
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* Conditional Section for Additional Fields */}
          {showDetails && (
            <Paper elevation={2} style={{ padding: '2rem', marginTop: '16px' }}>
              <Grid container spacing={2}>
                {/* Display Bill Details */}
                {complaintData.billDetails.map((billDetail, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <TextField label={billDetail.name} value={billDetail.value} fullWidth variant="outlined" disabled />
                  </Grid>
                ))}

                {/* Biller Response Fields */}
                <Grid item xs={12} md={4}>
                  <TextField label="Customer Name" value={complaintData.billerResponse.customerName} fullWidth variant="outlined" disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Amount" value={complaintData.billerResponse.amount} fullWidth variant="outlined" disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Due Date" value={complaintData.billerResponse.dueDate} fullWidth variant="outlined" disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Bill Date" value={complaintData.billerResponse.billDate} fullWidth variant="outlined" disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Bill Number" value={complaintData.billerResponse.billNumber} fullWidth variant="outlined" disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Bill Period" value={complaintData.billerResponse.billPeriod} fullWidth variant="outlined" disabled />
                </Grid>

                {/* Additional Info Fields */}
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    label="Reason for Complaint"
                    value={complaintReason}
                    onChange={(e) => setComplaintReason(e.target.value)}
                    fullWidth
                    variant="outlined"
                  >
                    {complaintReasons.map((reason) => (
                      <MenuItem key={reason} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Complaint Description"
                    value={complaintDescription}
                    onChange={(e) => setComplaintDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </Grid>

                {/* Submit Button for Registering Complaint */}
                <Grid container item justifyContent="flex-end" style={{ marginTop: '2%' }}>
                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      style={{ color: 'white', backgroundColor: '#027BFE', textTransform: 'none' }}
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? 'Submitting...' : 'Submit Complaint'}
                    </Button>
                  </Grid>
                </Grid>

                {/* Success/Error Messages */}
                {successMessage && (
                  <Typography variant="body1" style={{ color: 'green', marginTop: '10px' }}>
                    {successMessage}
                  </Typography>
                )}
                {error && (
                  <Typography variant="body1" style={{ color: 'red', marginTop: '10px' }}>
                    {error}
                  </Typography>
                )}
              </Grid>
            </Paper>
          )}
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default RegisterComplaint;
