import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  MobileFriendly,
  Tv,
  Lightbulb,
  Phone,
  Router,
  WaterDrop,
  GasMeterRounded,
  HealthAndSafety,
  VolunteerActivism,
  CreditCard,
  Home,
  LocalHospital,
  LocalTaxi,
  Repeat,
  Subscriptions,
} from "@mui/icons-material";

const serviceList = [
  { label: "Mobile Recharge", icon: <MobileFriendly />, component: "Mobile Recharge", categoryKey: "C00/C01" },
  { label: "Credit Card", icon: <CreditCard />, component: "Credit Card", categoryKey: "C15" },
  { label: "Electricity", icon: <Lightbulb />, component: "Electricity", categoryKey: "C04" },
  { label: "DTH", icon: <Tv />, component: "DTH", categoryKey: "C03" },
  { label: "Broadband", icon: <Router />, component: "Broadband", categoryKey: "C05" },
  { label: "Gas Cylinder", icon: <GasMeterRounded />, component: "Gas Cylinder", categoryKey: "C14" },
  { label: "Gas (PNG)", icon: <GasMeterRounded />, component: "Gas (PNG)", categoryKey: "C07" },
  { label: "Education Fee", icon: <VolunteerActivism />, component: "Education Fee", categoryKey: "C09" },
  { label: "Water", icon: <WaterDrop />, component: "Water", categoryKey: "C08" },
  { label: "Pay Loans", icon: <Repeat />, component: "Pay Loans", categoryKey: "C13" },
  { label: "Landline", icon: <Phone />, component: "Landline", categoryKey: "C02" },
  { label: "Rental", icon: <Home />, component: "Rental", categoryKey: "C24" },
  { label: "Donation", icon: <VolunteerActivism />, component: "Donation", categoryKey: "C12" },
  { label: "FASTag Recharge", icon: <LocalTaxi />, component: "FASTag Recharge", categoryKey: "C10" },
  { label: "Insurance", icon: <HealthAndSafety />, component: "Insurance", categoryKey: "C11" },
  { label: "Hospital & Pathology", icon: <LocalHospital />, component: "Hospital & Pathology", categoryKey: "C21" },
  { label: "Housing Society", icon: <HealthAndSafety />, component: "Housing Society", categoryKey: "C17" },
  { label: "Municipal Taxes", icon: <Home />, component: "Municipal Taxes", categoryKey: "C19" },
  { label: "Recurring Deposit", icon: <Repeat />, component: "Recurring Deposit", categoryKey: "C20" },
  { label: "Subscription", icon: <Subscriptions />, component: "Subscription", categoryKey: "C18" },
  { label: "NCMC Recharge", icon: <MobileFriendly />, component: "NCMC Recharge", categoryKey: "C28" },
  { label: "National Pension Scheme (NPS)", icon: <Repeat />, component: "National Pension Scheme (NPS)", categoryKey: "C30" },
  { label: "Electricity (Prepaid)", icon: <Lightbulb />, component: "Electricity (Prepaid)", categoryKey: "C29" },
  { label: "Clubs & Associations", icon: <Lightbulb />, component: "Clubs & Associations", categoryKey: "C22" },
];

export default function ServiceTabs({ setSelectedService, selectedService }) {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [billers, setBillers] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const index = serviceList.findIndex(
      (service) => service.component === selectedService
    );
    if (index !== -1) {
      setActiveTab(index);
    }
  }, [selectedService]);

  const fetchBillersByCategory = async (key) => {
    try {
      setLoading(true);
      setError("");

      // Retrieve JWT token from sessionStorage
      const jwtToken = sessionStorage.getItem("jwtToken");

      // API URL
      const apiUrl = `https://testapi.ipaisa.co.in/myapp/billPayments/getBillersBycategory?categoryKey=${key}&page=0&size=100`;

      // Fetch data
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      const data = await response.json();
      setBillers(data); // Set fetched billers
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const selectedService = serviceList[newValue];
    setSelectedService(selectedService.component);

    // Log the selected component and categoryKey
    console.log("Selected Service:", selectedService.component);
    console.log("Selected categoryKey:", selectedService.categoryKey);

    // Fetch billers by category
    fetchBillersByCategory(selectedService.categoryKey);
  };

  return (
    <Box
      sx={{
        borderRadius: "18px 18px 0px 0px",
        borderBottom: 1,
        borderColor: "divider",
        overflowX: "auto",
        backgroundColor: "#027BFE",
        padding: "2rem",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons
        aria-label="service tabs"
        ScrollButtonComponent={({ direction, onClick }) => (
          <IconButton
            onClick={onClick}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            {direction === "left" ? <ArrowBackIos /> : <ArrowForwardIos />}
          </IconButton>
        )}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "white",
          },
        }}
      >
        {serviceList.map((service, index) => (
          <Tab
            key={index}
            label={service.label}
            icon={React.cloneElement(service.icon, {
              style: {
                color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)",
              },
            })}
            sx={{
              color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)",
              textTransform: "capitalize",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
