import { Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const VirtualAccountNotReady = () => {
  const onApply = async () => {
    // console.log("onApply");
    const jwtToken = sessionStorage.getItem("jwtToken");
    try {
      const response = await axios.get(
        // "https://testapi.ipaisa.co.in/myapp/virtual/create",
        "https://testapi.ipaisa.co.in/myapp/virtual/create",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      console.log(response.status);
      if (response.status === 200) {
        Swal.fire({
          title: "You Request SuccessFull..!!",
          text:
            "Hii..." +
            response.data.label +
            "  Your Request is Generated wait for Admin Acceptance!!",
          icon: "success",
        });

        // toast.success(
        //   "Hii..." +
        //     response.data.label +
        //     "  Your Request is Generated wait for Admin Acceptance!!"
        // );
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Something Went Wrong!!",
        text: e.response.data.message,
        icon: "error",
      });
      // toast.error(e.response.data.message);
    }
  };
  return (
    <>
      <Typography sx={{fontSize:'22px', fontWeight:'bold', marginBottom:'1%'}}>Apply For Queen QR Code</Typography>
      <Box>
        <Paper sx={{ boxShadow:'none', boxShadow:'none', padding: 4 }}>
          <Stack spacing={6}>
            <Typography>
              Hit the Button to generate your personalized QR code. Once your
              application is processed, you'll receive a unique QR code that can
              be used
            </Typography>
            <Button variant="contained" onClick={onApply}>
              Apply
            </Button>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};

export default VirtualAccountNotReady;
