import axios from 'axios';
import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  UPDATE_PARTNERS,
  DELETE_PARTNER,
  DELETE_PARTNER_FAILURE,
  DELETE_PARTNER_SUCCESS,
  TOGGLE_PARTNER_STATUS,
} from '../actionTypes';
import { toast } from 'react-toastify';

export const fetchPartnersRequest = () => ({
  type: FETCH_PARTNERS_REQUEST,
});

export const fetchPartnersSuccess = (partners) => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: partners,
});

export const fetchPartnersFailure = (error) => ({
  type: FETCH_PARTNERS_FAILURE,
  payload: error,
});

// export const fetchPartners = () => {
//   return async (dispatch) => {
//     dispatch(fetchPartnersRequest());
//     try {
//       const token = sessionStorage.getItem('jwtToken');

//       if (!token) {
//         throw new Error('No JWT token found in local storage');
//       }

//       const response = await axios.get('https://testapi.ipaisa.co.in/myapp/auth/list', {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.status === 200) {
//         const partnerMap = {
//           CHANNELPARTNER: [],
//           SUPERDISTRIBUTOR: [],
//           MASTERDISTRIBUTOR: [],
//           AREADISTRIBUTOR: [],
//           RETAILER: [],
//           ADMIN: [],
//         };

//         // Map the partners based on `utype`
//         // response.data.users.forEach((partner) => {
//         //   if (partner.utype && partnerMap[partner.utype]) {
//         //     partnerMap[partner.utype].push(partner);
//         //   } else {
//         //     console.warn(`Unknown utype: ${partner.utype}`);
//         //   }
//         // });

//         response.data.users.forEach((partner) => {
//           // Check if 'utype' exists, 'partnerMap[partner.utype]' exists, and 'isDeleted' is "FALSE"
//           if (partner.utype && partnerMap[partner.utype] && partner.isDeleted === "FALSE") {
//             partnerMap[partner.utype].push(partner);
//           } else {
//             console.warn(`Unknown utype: ${partner.utype} or partner is deleted.`);
//           }
//         });

//         dispatch(fetchPartnersSuccess(partnerMap));
//       } else {
//         throw new Error('Failed to fetch partners');
//       }
//     } catch (error) {
//       console.error('API Error:', error);
//       dispatch(fetchPartnersFailure(error.message || 'An error occurred while fetching the partner list.'));
//     }
//   };
// };

export const updatePartners = (partners) => ({
  type: UPDATE_PARTNERS,
  payload: partners,
});

export const deletePartnerRequest = () => ({
  type: DELETE_PARTNER,
});

// Action creator for a successful delete
export const deletePartnerSuccess = (cpId) => ({
  type: DELETE_PARTNER_SUCCESS,
  payload: cpId,
});

// Action creator for a failed delete
export const deletePartnerFailure = (error) => ({
  type: DELETE_PARTNER_FAILURE,
  payload: error,
});

// Thunk action for deleting a partner
export const deletePartner = (cpId) => {
  return async (dispatch) => {
    dispatch(deletePartnerRequest());

    try {
      const token = sessionStorage.getItem('jwtToken');

      // Call the API to delete the partner
      const response = await axios.get(`https://testapi.ipaisa.co.in/myapp/auth/deletepartner/${cpId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Dispatch the success action to update the state
        dispatch(deletePartnerSuccess(cpId));
        toast.success('User deleted successfully');
      } else {
        dispatch(deletePartnerFailure('Failed to delete user'));
        toast.error('Please Assign His Child To Another User And Then Delete!');
      }
    } catch (error) {
      dispatch(deletePartnerFailure('Error deleting user'));
      toast.error('Please Assign His Child To Another User And Then Delete!');
    }
  };
};

export const togglePartnerStatus = (cpId) => ({
  type: TOGGLE_PARTNER_STATUS,
  payload: { cpId },
});
