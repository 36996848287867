import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, MenuItem, FormControl, InputLabel, Select, Typography, Grid, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { fetchPartners } from '../../redux/actions/partnerActions';
import { useDispatch, useSelector } from 'react-redux';


// Define validation schema
const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First name can only contain letters and spaces')
        .required('First name is required'),
    middleName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Middle name can only contain letters and spaces'),
    lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Last name can only contain letters and spaces')
        .required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    dob: Yup.date().required('Date of birth is required'),
    mobileNumber: Yup.string()
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile number is required'),
    bussinessName: Yup.string().required('Business Name is required'),
    utype: Yup.string().required('User Type is required'),
    category: Yup.string().required('Category is required'),
    bulkPayout: Yup.boolean(),
    address: Yup.string().required('Address is required'),
    pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid pincode')
        .required('Pincode is required'),
    aadharNumber: Yup.string().required('Aadhar number is required'),
    district: Yup.string().required('District is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    commissionsurcharge: Yup.string().required('Commission/Surcharge is required'),

    // Conditionally apply validation based on the value of the 'category' field
    percentage: Yup.number()
        .typeError('Percentage must be a number')
        .when('category', (category, schema) => {
            return category === 'fixed'
                ? schema.required('Percentage is required for fixed category').min(0).max(100)
                : schema.notRequired();
        }),


});


const AddUserDialog = ({ open, handleClose, handleAddUser }) => {
    const userId = sessionStorage.getItem('userId');
    const commORSur = sessionStorage.getItem('commORSur');
    const [setCharges, setSetCharges] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            dob: '',
            // countryCode: '+91',
            mobileNumber: '',
            // alternateMobileNumber: '',
            aadharNumber: '',
            bussinessName: '',
            utype: '',
            // selectParentId: '',
            // parentId: '',
            // gstin: '',
            category: '',
            bulkPayout: false,
            address: '',
            pincode: '',
            district: '',
            state: '',
            country: '',
            parentId: '',
            commissionsurcharge: commORSur || '',
            percentage: '',
            setCharges: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const token = sessionStorage.getItem('jwtToken');
                const parentId = values.parentId || userId;
                const setChargesValue = values.setCharges ? 'TRUE' : 'FALSE';

                const response = await axios.post('https://testapi.ipaisa.co.in/myapp/auth/register',
                    { ...values, parentId, setCharges: setChargesValue },  // Include parentId in the values
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const responseData = response.data;
                formik.resetForm(); // Reset form after successful save
                handleClose(); // Close the dialog
                if (responseData.body === "User saved successfully") {
                    handleAddUser(responseData); // Call handleAddUser if needed
                    toast.success(responseData.body);
                }
                else {
                    toast.error(responseData.message);
                }
            } catch (error) {
                if (error.response && error.response.status === 406) {
                    const responseData = error.response.data;
                    toast.error(responseData.message);

                } else {
                    console.error("Error during registration:", error);
                    toast.error("An error occurred during registration. Please try again later.");
                }
            }
            finally {
                setSubmitting(false);
            }
            // WhatsApp API call
            const payload = {
                to: values.mobileNumber,
                text1: `${values.firstName} ${values.middleName} ${values.lastName}`,
                text2: values.mobileNumber,
                text3: 'Please Set Your Mpin',
            };
            const token = sessionStorage.getItem('jwtToken');

            const whatsappResponse = await axios.post('https://testapi.ipaisa.co.in/myapp/auth/whatsapp/msg', payload, {

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Add Bearer token to the headers
                },
            });

            if (whatsappResponse.status === 200) {
                console.log('WhatsApp message sent successfully');
            } else {
                console.error('Failed to send WhatsApp message');
            }

        }
    });
    const [pincode, setPincode] = useState('');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [parentPartners, setParentPartners] = useState([]);
    const [hierarchyUsers, setHierarchyUsers] = useState([]); // Store hierarchy users data

    const handleCheckboxChange = (event) => {
        setSetCharges(event.target.checked);
    };
    const userType = sessionStorage.getItem('uType');
    const dispatch = useDispatch();
    const partners = useSelector((state) => state.partner.partners);



    useEffect(() => {
        // dispatch(fetchPartners());
    }, [dispatch]);

    useEffect(() => {
        console.log('Fetched Partners:', partners);
    }, [partners]);



    useEffect(() => {
        const fetchData = async () => {
            if (pincode.length === 6) {
                try {
                    const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
                    const result = await response.json();
                    setData(result);
                } catch (error) {
                    console.error('Error fetching pin data:', error);
                    setError('Failed to fetch data. Please try again later.');
                }
            }
        };

        const timeoutId = setTimeout(() => {
            fetchData();
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [pincode]);

    useEffect(() => {
        if (data && data[0]?.Status === 'Success') {
            const postOffice = data[0].PostOffice[0];
            formik.setFieldValue('district', postOffice.District || '');
            formik.setFieldValue('state', postOffice.State || '');
            formik.setFieldValue('country', postOffice.Country || '');
            formik.setFieldValue('pincode', postOffice.Pincode || '');
        }
    }, [data]);


    useEffect(() => {
        const callAadhaarAPI = async (aadhaarNumber) => {
            try {
                const response = await axios.post('https://test.ipaisa.co.in/myapp/auth/instantpay/aadhaar', {
                    aadhaarNumber,

                });
                console.log('Aadhaar API response:', response.data);
            } catch (error) {
                console.error('Error calling Aadhaar API:', error);
            }
        };

        if (formik.values.aadharNumber.length === 12) {
            callAadhaarAPI(formik.values.aadharNumber);
        }
    }, [formik.values.aadharNumber]);

    const handlePincodeChange = (e) => {
        const pincode = e.target.value;
        const regex = /^[0-9\b]+$/;

        if (pincode === '' || (regex.test(pincode) && pincode.length <= 6)) {
            setPincode(pincode);
        }
    };
    const handleUserTypeChange = async (event) => {
        const selectedUserType = event.target.value;
        formik.setFieldValue("utype", selectedUserType);

        // Make the API call to get hierarchy users based on selectedUserType
        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://testapi.ipaisa.co.in/myapp/auth/gethierarchibyusertype/${selectedUserType}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === 200) {
                setHierarchyUsers(response.data.users);
                console.log("Hierarchy users:", response.data.users);
            } else {
                console.error("Failed to fetch hierarchy users");
            }
        } catch (error) {
            console.error("Error fetching hierarchy users:", error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Validation check (if needed)
        let val = value;
        if (type === 'checkbox') {
            val = checked;
        }

        // Additional validation based on field name
        if (name === 'mobileNumber' || name === 'aadharNumber') {
            // Remove non-numeric characters and restrict length
            val = val.replace(/\D/g, '').slice(0, name === 'aadharNumber' ? 12 : 10);
            formik.setFieldValue(name, val);

        } else if (name === 'email') {
            // Simple email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (val && !emailRegex.test(val)) {
                formik.setFieldError(name, 'Invalid email address');
            } else {
                formik.setFieldError(name, '');
            }
            formik.setFieldValue(name, val);
        } else {
            // Update Formik values for other fields
            formik.setFieldValue(name, val);

            // Manually validate the field if it's touched
            if (formik.touched[name]) {
                formik.validateField(name).catch(() => { });
            }
        }

        // Manually validate the field if it's touched
        if (formik.touched[name]) {
            formik.validateField(name).catch(() => { });
        }
    };


    const handleCancel = () => {
        handleClose();
        formik.resetForm(); // Reset form on cancel
    };

    const parentID = (() => {
        return [
            { label: 'This section in Progress' }
        ]
    });
    const userTypeOptions = (() => {
        const userType = sessionStorage.getItem('uType');
        // const utype = formik.values.utype;

        if (userType === 'ADMIN') {
            return [
                { value: 'CHANNELPARTNER', label: 'Channel Partner' },
                { value: 'SUPERDISTRIBUTOR', label: 'Super Distributor' },
                { value: 'MASTERDISTRIBUTOR', label: 'Master Distributor' },
                { value: 'AREADISTRIBUTOR', label: 'Area Distributor' },
                { value: 'RETAILER', label: 'Retailer' },
                { value: 'TECHSUPPORT', label: 'Tech Support' }
            ];
        } else if (userType === 'CHANNELPARTNER') {
            return [
                { value: 'SUPERDISTRIBUTOR', label: 'Super Distributor' },
                { value: 'MASTERDISTRIBUTOR', label: 'Master Distributor' },
                { value: 'AREADISTRIBUTOR', label: 'Area Distributor' },
                { value: 'RETAILER', label: 'Retailer' }
            ];
        } else if (userType === 'SUPERDISTRIBUTOR') {
            return [
                { value: 'MASTERDISTRIBUTOR', label: 'Master Distributor' },
                { value: 'AREADISTRIBUTOR', label: 'Area Distributor' },
                { value: 'RETAILER', label: 'Retailer' }
            ];
        } else if (userType === 'MASTERDISTRIBUTOR') {
            return [
                { value: 'AREADISTRIBUTOR', label: 'Area Distributor' },
                { value: 'RETAILER', label: 'Retailer' }
            ];
        } else if (userType === 'AREADISTRIBUTOR') {
            return [
                { value: 'RETAILER', label: 'Retailer' }
            ];
        } else {
            // Return an empty array or default options if none of the conditions match
            return [];
        }
    })();


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            sx={{
                width: { xs: '100%', sm: '600px', md: '700px' },
                marginLeft: { xs: 0, md: 50 },
                margin: { xs: 'auto' }
            }}
        >
            <DialogTitle bgcolor={'#027BFE'} color={'white'}>Add User</DialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>Personal Details</Typography>

                <Box p={{ xs: 1, md: 2 }}>

                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    margin="dense"
                                    name="firstName"
                                    label="First Name"
                                    type="text"
                                    value={formik.values.firstName}
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    margin="dense"
                                    name="middleName"
                                    label="Middle Name"
                                    type="text"
                                    value={formik.values.middleName}
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    margin="dense"
                                    name="lastName"
                                    label="Last Name"
                                    type="text"
                                    value={formik.values.lastName}
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="dense"
                                    name="dob"
                                    label="Date of Birth"
                                    type="date"
                                    value={formik.values.dob}
                                    onChange={handleInputChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    autoComplete="off"
                                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                                    helperText={formik.touched.dob && formik.errors.dob}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>


                            <Grid item xs={12} sm={6} /*ml={2}*/>
                                <TextField
                                    margin="dense"
                                    name="mobileNumber"
                                    label="Mobile Number"
                                    type="text"
                                    value={formik.values.mobileNumber}
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                    helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} /*sm={5.6}*/>
                                <TextField
                                    margin="dense"
                                    name="aadharNumber"
                                    label="Aadhar Number"
                                    type="text"
                                    value={formik.values.aadharNumber}
                                    onChange={handleInputChange}  // This will call the updated function
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.aadharNumber && Boolean(formik.errors.aadharNumber)}
                                    helperText={formik.touched.aadharNumber && formik.errors.aadharNumber}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                <Typography variant="h6" gutterBottom>Business Details</Typography>

                <Box p={2}>
                    <Grid container spacing={2}>

                        {/* First Row */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="bussinessName"
                                label="Outlet Name"
                                type="text"
                                value={formik.values.bussinessName}
                                onChange={handleInputChange}
                                fullWidth
                                autoComplete="off"
                                error={formik.touched.bussinessName && Boolean(formik.errors.bussinessName)}
                                helperText={formik.touched.bussinessName && formik.errors.bussinessName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} marginTop={1}>
                            <FormControl fullWidth>
                                <InputLabel>User Type</InputLabel>
                                <Select
                                    name="utype"
                                    label="User Type"
                                    value={formik.values.utype}
                                    onChange={handleUserTypeChange}
                                    error={formik.touched.utype && Boolean(formik.errors.utype)}
                                >
                                    {userTypeOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.utype && (
                                    <Typography variant="body2" color="error">
                                        {formik.errors.utype}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        {/* Second Row */}

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={formik.touched.category && Boolean(formik.errors.category)}>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    margin="dense"
                                    name="category"
                                    label="Category"
                                    value={formik.values.category}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="fixed">Fixed</MenuItem>
                                    <MenuItem value="variable">Variable</MenuItem>
                                </Select>
                                {formik.touched.category && formik.errors.category ? (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.category}
                                    </Typography>
                                ) : null}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Select Parent ID</InputLabel>
                                <Autocomplete
                                    options={hierarchyUsers}
                                    getOptionLabel={(option) => `${option.userId} - ${option.usernname}`}
                                    onChange={(event, value) => formik.setFieldValue('parentId', value ? value.userId : '')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="parentId"
                                            label="Select Parent ID"
                                            variant="outlined"
                                            error={formik.touched.parentId && Boolean(formik.errors.parentId)}
                                            helperText={formik.touched.parentId && formik.errors.parentId}
                                        />
                                    )}
                                    value={hierarchyUsers.find(user => user.userId === formik.values.parentId) || null}
                                />
                            </FormControl>
                        </Grid>


                        {/* Third Row */}
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                error={formik.touched.commissionsurcharge && Boolean(formik.errors.commissionsurcharge)}
                            >
                                <InputLabel>Commission/Surcharge</InputLabel>
                                <Select
                                    margin="dense"
                                    label="Commission/Surcharge"
                                    name="commissionsurcharge"
                                    value={formik.values.commissionsurcharge}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    autoComplete="off"
                                    disabled={userType !== 'ADMIN'} // Enable only if userType is ADMIN
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="COMMISSION">Commission</MenuItem>
                                    <MenuItem value="SURCHARGE">Surcharge</MenuItem>
                                </Select>
                                {formik.touched.commissionsurcharge && formik.errors.commissionsurcharge ? (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.commissionsurcharge}
                                    </Typography>
                                ) : null}
                            </FormControl>
                        </Grid>
                        {formik.values.category !== 'variable' && (
                            <Grid item xs={12} sm={6} mt={-1}>
                                <TextField
                                    margin="dense"
                                    name="percentage"
                                    label="Percentage"
                                    type="text"
                                    value={formik.values.percentage}
                                    onChange={handleInputChange}
                                    fullWidth
                                    autoComplete="off"
                                    error={formik.touched.percentage && Boolean(formik.errors.percentage)}
                                    helperText={formik.touched.percentage && formik.errors.percentage}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {/* Fourth Row */}
                    <Grid style={{ display: 'flex', gap: '4%' }}>
                        <Grid>
                            {(formik.values.utype === 'AREADISTRIBUTOR' &&
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="bulkPayout"
                                                checked={formik.values.bulkPayout}
                                                onChange={handleInputChange}
                                            />
                                        }
                                        label="Bulk Payout"
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='setCharges'
                                        checked={formik.values.setCharges}
                                        onChange={() => {
                                            formik.setFieldValue('setCharges', !formik.values.setCharges);
                                        }}
                                    />
                                }
                                label='Set Charges'
                            />
                        </Grid>

                        {/* {(formik.values.utype === 'RETAILER' &&                     
                            <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name='Set PayOut'
                                    />
                                }
                                label='Set PayOut'
                            />
                            </Grid>
                        )} */}

                    </Grid>
                </Box>



                <Typography variant="h6" gutterBottom>Address Details</Typography>

                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Pincode"
                                variant="outlined"
                                fullWidth
                                value={pincode}
                                onChange={handlePincodeChange}
                                inputProps={{ maxLength: 6 }}
                                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                                helperText={formik.touched.pincode && formik.errors.pincode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="District"
                                variant="outlined"
                                fullWidth
                                value={formik.values.district || ''}
                                InputProps={{ readOnly: true }}
                                error={formik.touched.district && Boolean(formik.errors.district)}
                                helperText={formik.touched.district && formik.errors.district}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="State"
                                variant="outlined"
                                fullWidth
                                value={formik.values.state || ''}
                                InputProps={{ readOnly: true }}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Country"
                                variant="outlined"
                                fullWidth
                                value={formik.values.country || ''}
                                InputProps={{ readOnly: true }}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                variant="outlined"
                                fullWidth
                                value={formik.values.address}
                                onChange={(e) => formik.setFieldValue('address', e.target.value)}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button
                            onClick={handleCancel}
                            color="primary"
                            sx={{
                                width: '250px',
                                height: '54px',
                                borderRadius: '12px',
                                border: '1px solid',
                                backgroundColor: '#FF0000',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#FF0000', color: '#ffffff'
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={formik.isSubmitting || !formik.isValid}
                            sx={{
                                width: '250px',
                                height: '54px',
                                borderRadius: '12px',
                                border: '1px solid',
                                backgroundColor: '#027BFE',
                                color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#027BFE',
                                    color: '#FFFFFF',
                                },
                            }}
                        >
                            Save
                        </Button>

                    </Box>
                </DialogActions>
            </DialogContent>

        </Dialog>

    );
};

export default AddUserDialog;
