import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  InputBase,
  IconButton,
} from "@mui/material";
import DownloadIcon1 from "@mui/icons-material/Download";
import { Grid } from "@material-ui/core";
import HiePayInInvoice from "./HiePayInInvoice";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import HiePayoutInvoice from "./HiePayoutInvoice";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import HieFTRInvoice from "./HieFTRInvoice";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const TransactionTable = ({
  transactionType,
  userRecords,
  page,
  rowsPerPage,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [payInData, setPayInData] = useState([]);
  const [payOutData, setPayOutData] = useState([]);
  const [ftrData, setFtrData] = useState([]);
  const classes = useStyles();

  // Derived data for the tableuserRecords
  const tableData = searchQuery.trim()
    ? transactionType === "Pay-In"
      ? payInData
      : payOutData
    : userRecords;

  // Search handler
  const handleSearchIcon = async () => {
    if (!searchQuery.trim()) {
      toast.warning("Search query is empty!");
      return; // No need to call search if the query is empty
    }

    setLoading(true);
    const jwtToken = sessionStorage.getItem("jwtToken");

    try {
      // Define the API URL based on transaction type
      let apiUrl = "";
      if (transactionType === "Pay-In") {
        apiUrl = "https://testapi.ipaisa.co.in/myapp/admin/searchInPayInReport";
      } else if (transactionType === "Pay-Out") {
        apiUrl =
          "https://testapi.ipaisa.co.in/myapp/admin/getAllPayoutTxnsByUserId";
      } else if (transactionType === "FTR") {
        apiUrl = "https://testapi.ipaisa.co.in/myapp/admin/hierarchyFtrReport";
      }
      // Call the API with headers and search query parameters
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: {
          searchBy: searchQuery,
        },
      });

      // For 'Pay-In' transaction type
      if (transactionType === "Pay-In") {
        const data = response.data.body.data || [];
        if (data.length === 0) {
          toast.info(
            "No Pay-In transactions found for the given search query."
          );
        } else {
          setPayInData(data);
        }
      } else {
        // For 'Pay-Out' transaction type
        const data = response.data.body.data.transaction || [];

        // Handle three conditions for 'Pay-Out' response
        if (data.length === 0) {
          toast.info(
            "No Pay-Out transactions found for the given search query."
          );
        } else if (data.length > 0 && data[0].status === "error") {
          // If transactions are present but have errors
          toast.error("There was an issue with the Pay-Out transaction data.");
        } else {
          // Successful data for Pay-Out
          setPayOutData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      toast.error("Failed to fetch search results. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Payin Invoice
  const handleDownloadPayinInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.txnid}`);

    if (!receiptElement) {
      console.error("Receipt element not found:", `receipt-${data.txnid}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Hierarchy Payin Receipt-${data.txnid}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  // Payout Invoice
  const handleDownloadPayoutInvoice = async (data) => {
    const receiptElement = document.getElementById(
      `receipt-${data.externalRef}`
    );

    if (!receiptElement) {
      console.error(
        "Receipt element not found:",
        `receipt-${data.externalRef}`
      );
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Hierarchy Payout Receipt-${data.externalRef}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  // FTR Invoice
  const handleDownloadFTRInvoice = async (data) => {
    const receiptElement = document.getElementById(
      `receipt-${data.transactionId}`
    );

    if (!receiptElement) {
      console.error(
        "Receipt element not found:",
        `receipt-${data.transactionId}`
      );
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `FTR Receipt-${data.transactionId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "success":
        return "green";
      case "pending":
        return "orange";
      case "failure":
        return "red";
      case "refund":
        return "darkgoldenrod";
      default:
        return "inherit";
    }
  };

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case "success":
        return "Successful";
      case "pending":
        return "Pending";
      case "failure":
        return "Failed";
      case "refund":
        return "Refund";
      default:
        return status || "N/A";
    }
  };

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value); // Update search query state
  };
  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If the search query is empty, fetch the default payout data
      // fetchPayOutData(0, '', '');
    }
  }, [searchQuery]); // Only runs when searchQuery changes

  return (
    <>
      <ToastContainer />
      <Grid style={{ marginTop: "3%" }}>
        {/* Search Field */}
        <Grid style={{ marginTop: "3%" }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              maxWidth: { xs: "100%", sm: "20%" },
              borderRadius: "10px",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              padding: "0.5rem",
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInput} // Updates search query
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchIcon(); // Trigger search when Enter is pressed
                }
              }}
            />
            <IconButton color="primary" onClick={handleSearchIcon}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
        <TableContainer
          component={Paper}
          sx={{
            marginTop: "2%",
            boxShadow: "none",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
              <TableRow>
                {/* Common Columns */}
                <TableCell style={{ color: "white" }} align="start">
                  Sr. No
                </TableCell>
                {transactionType === "Pay-In" ? (
                  <>
                    <TableCell style={{ color: "white" }} align="start">
                      Transaction ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      User ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Phone
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Mode
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Date
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Status
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      PayIn Amount
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Amount
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Settlement
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Invoice
                    </TableCell>
                  </>
                ) : transactionType === "Pay-Out" ? (
                  <>
                    <TableCell style={{ color: "white" }} align="start">
                      External Ref
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Payee Account
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Payee Name
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Date
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Order ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Wallet Opening Balance
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Amount
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Wallet Closing Balance
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Remark
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Status
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Transfer Mode
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Invoice
                    </TableCell>
                  </>
                ) : transactionType === "FTR" ? (
                  <>
                    <TableCell style={{ color: "white" }} align="start">
                      Txn. ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Sender ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Sender Name
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Receiver ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Receiver Name
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Date
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Transfer Mode
                    </TableCell>
                    {/* <TableCell style={{ color: 'white' }} align="start">Opening Balance</TableCell> */}
                    <TableCell style={{ color: "white" }} align="start">
                      Transfer Amount
                    </TableCell>
                    {/* <TableCell style={{ color: 'white' }} align="start">Closing Balance</TableCell> */}
                    <TableCell style={{ color: "white" }} align="start">
                      Remarks
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="start">
                      Invoice
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length > 0 ? (
                tableData.map((record, index) => (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${
                      index % 2 === 0 ? classes.oddRow : classes.evenRow
                    }`}
                  >
                    {/* Pay-In or Pay-Out specific rendering */}
                    {transactionType === "Pay-In" ? (
                      <>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{record.txnid || "N/A"}</TableCell>
                        <TableCell>{record.userId || "N/A"}</TableCell>
                        <TableCell>{record.phone || "N/A"}</TableCell>
                        <TableCell>{record.mode || "N/A"}</TableCell>
                        <TableCell>
                          {new Date(record.addedon || "").toLocaleString()}
                        </TableCell>
                        <TableCell
                          style={{
                            color: getStatusColor(record.status),
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {getStatusText(record.status)}
                        </TableCell>
                        <TableCell>
                          {Number(record.netAmountDebit || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(record.amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>{record.settlement || "N/A"}</TableCell>
                        <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadPayinInvoice(record)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        <Grid
                          style={{
                            position: "absolute",
                            left: "-9999px",
                            top: "0px",
                          }}
                        >
                          <Grid id={`receipt-${record.txnid}`}>
                            <HiePayInInvoice data={record} />
                          </Grid>
                        </Grid>
                      </>
                    ) : transactionType === "Pay-Out" ? (
                      <>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{record.externalRef || "N/A"}</TableCell>
                        <TableCell>{record.payeeAccount || "N/A"}</TableCell>
                        <TableCell>{record.payeeName || "N/A"}</TableCell>
                        <TableCell>
                          {new Date(record.timestamp || "").toLocaleString()}
                        </TableCell>
                        <TableCell>{record.orderid || "N/A"}</TableCell>
                        <TableCell>
                          {Number(record.walletOpeningBalance || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(record.txnValue || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(record.walletClosingBalance || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>{record.remark || "N/A"}</TableCell>
                        <TableCell
                          style={{
                            color: getStatusColor(record.status),
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {getStatusText(record.status)}
                        </TableCell>
                        <TableCell>{record.transfer_mode || "N/A"}</TableCell>
                        <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadPayoutInvoice(record)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        <Grid
                          style={{
                            position: "absolute",
                            left: "-9999px",
                            top: "0px",
                          }}
                        >
                          <Grid id={`receipt-${record.externalRef}`}>
                            <HiePayoutInvoice data={record} />
                          </Grid>
                        </Grid>
                      </>
                    ) : transactionType === "FTR" ? (
                      <>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{record.transactionId || "N/A"}</TableCell>
                        <TableCell>{record.sender || "N/A"}</TableCell>
                        <TableCell>{record.senderUserName || "N/A"}</TableCell>
                        <TableCell>{record.reciver || "N/A"}</TableCell>
                        <TableCell>
                          {record.receiverUserName || "N/A"}
                        </TableCell>
                        <TableCell>
                          {new Date(record.date || "").toLocaleString()}
                        </TableCell>
                        <TableCell>{record.transactionType || "N/A"}</TableCell>
                        {/* <TableCell>{Number(record.wOpeningBlc || 0).toFixed(2)}</TableCell> */}
                        <TableCell>
                          {Number(record.amount || 0).toFixed(2)}
                        </TableCell>
                        {/* <TableCell>{Number(record.wClosingBlc || 0).toFixed(2)}</TableCell> */}
                        <TableCell>{record.remark || "N/A"}</TableCell>
                        <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadFTRInvoice(record)}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                        <Grid
                          style={{
                            position: "absolute",
                            left: "-9999px",
                            top: "0px",
                          }}
                        >
                          <Grid id={`receipt-${record.transactionId}`}>
                            <HieFTRInvoice data={record} />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default TransactionTable;
