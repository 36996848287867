import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AddUserDialog from "./AddUserDialog";
import "react-toastify/dist/ReactToastify.css";
import EditUserDialog from "./EditUserDialog";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    Grid,
    TablePagination,
    Switch,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CardMedia,
    Card,
    InputBase,
    TextField,
    Typography,
    Modal
} from "@mui/material";
import {
    Visibility,
    Edit,
    Delete,
    ChevronLeft,
    ChevronRight,
} from "@mui/icons-material";
import axios from "axios";
import SearchNormalIcon from "../Assets/Partner/Search.svg";
import AddUserIcon from "../Assets/Partner/Add.svg";
import ViewUserDialog from "./ViewUserDialog";
import { useSelector, useDispatch } from "react-redux";
import {
    deletePartner,
    fetchPartners,
    togglePartnerStatus,
    updatePartners,
} from "../../redux/actions/partnerActions";
import { makeStyles } from "@mui/styles";
import UserCreationSuccessPopup from "./UserCreationSuccessPopup";
import UserCreationFailedPopup from "./UserCreationFailedPopup";
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: "#C1E0FB",
    },
    oddRow: {
        backgroundColor: "#FFFFFF",
    },
}));

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    middleName: Yup.string().required("Middle Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    dob: Yup.date().required("Date of Birth is required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
    alternateMobileNumber: Yup.string().required(
        "Alternate Mobile Number is required"
    ),
    email: Yup.string().email("Invalid email").required("Email is required"),
});

const SdlistTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [partners, setPartners] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const partners = useSelector((state) => state.partner.partners);
    const superdistributor = partners || [];
    const [showSuccessPop, setShowSuccessPop] = useState(false);
    const [showFailedPop, setShowFailedPop] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    useEffect(() => {
        fetchPartnersFromApi(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchPartnersFromApi(newPage, rowsPerPage); // Fetch data for the new page
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to page 0 when rows per page changes
        fetchPartnersFromApi(0, newRowsPerPage); // Fetch data for the first page with new rows per page
    };
    const handleSearchQueryChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value.trim() === '') {
            // If search query is cleared, fetch partners with default params
            fetchPartnersFromApi(0);
        }
    };

    const handleSearch = async () => {
        if (searchQuery.trim() === '') {
            toast.info('Please enter a search term.');
            return;
        }

        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://testapi.ipaisa.co.in/myapp/auth/searchInUserList?roleid=3&searchBy=${encodeURIComponent(searchQuery)}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.statusCodeValue === 200 && response.data.body) {
                setPartners(response.data.body);
                setTotalDataCount(response.data.totalCount || 0); // Update total count
            } else {
                setPartners([]); // Clear table if no results
                toast.info('No results found.');
            }
        } catch (error) {
            console.error('Error searching users:', error);
            toast.error('Error fetching search results. Please try again.');
        }
    };

    const fetchPartnersFromApi = async (pageNum) => {
        try {
            const token = sessionStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://testapi.ipaisa.co.in/myapp/auth/list?pageNumber=${pageNum}&roleid=3`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.status === 200 && response.data.users) {
                setPartners(response.data.users);
                setIsLastPage(response.data.users.length < rowsPerPage);
                setTotalDataCount(response.data.length); // Assuming the API returns total records count
                console.log('Fetched Partners:', response.data.users);

            } else if (response.data.status === 404) {
                console.log('Response status 404:', response.data.message); // Ensure it reaches here
                toast.info(response.data.message);
                // If no data on the first page, navigate to the second page and check there
                if (pageNum === 0) {
                    setPageNumber(1); // Move to second page if first page returns 404
                    fetchPartnersFromApi(1); // Call the API for the second page
                } else {
                    setPartners([]); // No data, set empty table
                    setIsLastPage(true); // Set last page as true if no more data
                }
            }
        } catch (error) {
            console.error('Error fetching partners:', error);
            toast.error('Error fetching partners. Please try again later.');
        }
    };
    const handleClosePop = () => {
        setShowSuccessPop(false);
        setShowFailedPop(false);
    };

    const pageDataCache = {};


    const handlePageChange = async (newPage) => {
        if (pageDataCache[newPage]) {
            setPartners(pageDataCache[newPage]); // Use cached data if available
        } else {
            await fetchPartnersFromApi(newPage);
            pageDataCache[newPage] = partners; // Cache data for the page
        }
    };

    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPageNumber(0); // Reset to the first page
        fetchPartnersFromApi(0); // Fetch data for the new rowsPerPage
    };

    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [userType, setUserType] = useState("");
    const [viewRow, setViewRow] = useState(null);
    const [totalUsers, setTotalUsers] = useState(0); // To store total users count for pagination

    useEffect(() => {
        const storedUserType = sessionStorage.getItem("uType");
        setUserType(storedUserType);
    }, []);

    const [newUser, setNewUser] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        mobileNumber: "",
        alternateMobileNumber: "",
        email: "",
        country: null,
        state: null,
        city: null,
    });
    const [addUserOpen, setAddUserOpen] = useState(false);
    const handleSearchClick = () => {
        setShowSearchInput(!showSearchInput);
    };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setViewRow(row);
        setOpen(true);
    };

    const handleEditOpen = (row) => {
        setEditRow(row);
        setEditOpen(true);
    };

    const handleAddUserOpen = () => {
        setAddUserOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setEditRow(null);
    };

    const handleSaveEdit = (editedUser) => {
        const editedIndex = partners.findIndex((user) => user.userid === editedUser.userid);
        if (editedIndex !== -1) {
            const newData = [...partners];
            newData[editedIndex] = editedUser;
            setPartners(newData); // Update local state

            // Dispatch the update user action if using Redux
            dispatch(updatePartners(editedUser));

            toast.success("User details updated successfully");
            setShowSuccessPop(true); // Show success pop-up
        } else {
            toast.error("Error updating user details");
            setShowFailedPop(true); // Show failed pop-up
        }
    };


    const handleAddUser = (values) => {
        // console.log('New User:', values);
        // toast.success('User added successfully');
        setShowSuccessPop(true);
        fetchPartnersFromApi(0, 3);

    };

    const handleAddUserClose = () => {
        setAddUserOpen(false);
        // // dispatch(fetchPartners());
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (editOpen) {
            setEditRow({ ...editRow, [name]: value });
        } else {
            setNewUser({ ...newUser, [name]: value });
        }
    };

    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [selectedUser, setSelectedUser] = useState(null); // Store selected user's details

    const handleOpenModal = (user) => {
        setSelectedUser(user); // Set the user to be deleted
        setOpenModal(true); // Open the modal
    };

    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal without deleting
    };

    const handleDelete = () => {
        // Call the deletePartner action to handle deletion
        dispatch(deletePartner(selectedUser.userid));
        handleCloseModal(); // Close the modal after deletion
        fetchPartnersFromApi(0, 3);

    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const handleStatusToggle = async (sdId, currentStatus) => {
        try {
            const token = sessionStorage.getItem("jwtToken");
            const newStatus = currentStatus === "Active" ? "Inactive" : "Active";

            const response = await axios.put(
                `https://testapi.ipaisa.co.in/myapp/admin/changeStatus/${sdId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Check the response status and show the appropriate toast message
            if (response.data.message === "true") {
                dispatch(togglePartnerStatus(sdId));
                toast.success(response.data.status); // Success message from the response
                // // dispatch(fetchPartners());
            } else {
                toast.error(`Error: ${response.data.status}`);
            }
        } catch (error) {
            console.error("Error toggling user status:", error);
            toast.error("Error toggling user status. Please try again later.");
        }
    };

    const filteredData = partners.filter((row) => {
        return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const inputHeight = "56px";

    return (
        <Box sx={{ padding: 3, marginTop:{xs:'20%', md:'5%'},  backgroundColor: '#F4F5FA', height: '100vh' }}>
m            <Box mt={4} mb={2}>
                <Grid
                    container
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Grid>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '22px', marginBottom: { xs: '4%', md: '0%' } }}>
                            Super Distributors
                        </Typography>
                    </Grid>
                    <Grid
                        style={{ display: "flex", gap: "2%" }}
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                    >
                        <Grid item>
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchQueryChange} // Handle search query changes
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {userType !== "RETAILER" && (
                            <Grid item>
                                <Button
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        height: inputHeight,
                                    }}
                                    onClick={handleAddUserOpen}
                                >
                                    <ControlPointIcon style={{ marginRight: 8 }} />
                                    Add User
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: "2%" }}>
                    <Table>
                        <TableHead style={{ backgroundColor: "#027BFE" }}>
                            <TableRow>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    Sr.No
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    User ID
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    Parent ID
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                                    Name
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                                    Business Name
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    Mobile Number
                                </TableCell>
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    Wallet Balance
                                </TableCell>
                                {userType === "ADMIN" ? (
                                    <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                        Status
                                    </TableCell>
                                ) : (
                                    <></>
                                )}
                                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow
                                        key={row.userid}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                                    >
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {index + 1 + page * rowsPerPage}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {row.userid}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {row.parentId}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "start" }}>
                                            {row.firstName} {row.middleName} {row.lastName}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "start" }}>
                                            {row.bussinessName}
                                        </TableCell>
                                        {/* <TableCell sx={{ textAlign: 'center' }}>{row.pincode}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{row.email}</TableCell> */}
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {row.mobileNumber}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {Number(row.walletBalance).toFixed(2)}
                                        </TableCell>
                                        {userType === "ADMIN" ? (
                                            <TableCell sx={{ textAlign: "center" }}>
                                                {userType === "ADMIN" ? (
                                                    <Switch
                                                        onChange={() =>
                                                            handleStatusToggle(row.userid, row.status)
                                                        }
                                                        checked={row.status === "ACTIVE"}
                                                        sx={{
                                                            "& .MuiSwitch-switchBase.Mui-checked": {
                                                                color: "green",
                                                            },
                                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                                            {
                                                                backgroundColor: "green",
                                                            },
                                                            "& .MuiSwitch-switchBase": {
                                                                color: "red",
                                                            },
                                                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                                backgroundColor: "red",
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    <Switch
                                                        checked={row.status === "ACTIVE"}
                                                        sx={{
                                                            "& .MuiSwitch-switchBase.Mui-checked": {
                                                                color: "green",
                                                            },
                                                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                                            {
                                                                backgroundColor: "green",
                                                            },
                                                            "& .MuiSwitch-switchBase": {
                                                                color: "red",
                                                            },
                                                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                                backgroundColor: "red",
                                                            },
                                                        }}
                                                        disabled
                                                    />
                                                )}
                                            </TableCell>
                                        ) : (
                                            <></>
                                        )}
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <IconButton onClick={() => handleClickOpen(row)}>
                                                <Visibility />
                                            </IconButton>
                                            {userType === "ADMIN" && (
                                                <>
                                                    <IconButton onClick={() => handleEditOpen(row)}>
                                                        <Edit />
                                                    </IconButton>
                                                    {/* Delete button that opens the modal */}
                                                    <IconButton onClick={() => handleOpenModal(row)}>
                                                        <Delete />
                                                    </IconButton>

                                                    {/* Confirmation Modal */}
                                                    <Modal open={openModal} onClose={handleCloseModal}>
                                                        <Box sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            bgcolor: 'background.paper',
                                                            boxShadow: 24,
                                                            p: 4,
                                                            borderRadius: 2,
                                                        }}>
                                                            <Typography variant="h6" component="h2">
                                                                Confirm Delete?
                                                            </Typography>
                                                            <Typography sx={{ mt: 2 }}>
                                                                Are you sure you want to delete the User Number <b>{selectedUser?.mobileNumber}</b> with User ID: <b>{selectedUser?.userid}</b>?
                                                            </Typography>

                                                            {/* Buttons for Confirm and Cancel actions */}
                                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Button variant="outlined" onClick={handleCloseModal} sx={{ mr: 2 }}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="contained" color="error" onClick={handleDelete}>
                                                                    Delete
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Modal>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalDataCount} // Total number of rows
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    onPageChange={(event, newPage) => {
                        setPageNumber(newPage); // Set the new page number
                        handlePageChange(newPage); // Fetch partners for the new page or use cached data
                    }}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    nextIconButtonProps={{ disabled: isLastPage }} // Disable if it's the last page
                    backIconButtonProps={{ disabled: pageNumber === 0 }} // Disable if it's the first page
                />
            </Box>

            <AddUserDialog
                open={addUserOpen}
                handleClose={handleAddUserClose}
                handleAddUser={handleAddUser}
            />
            {editRow && (
                <EditUserDialog
                    open={editOpen}
                    handleClose={handleEditClose}
                    user={editRow}
                    handleSave={handleSaveEdit}
                    handlePath="SDLIST"
                    onEditComplete={() => fetchPartnersFromApi(0, 3)} // Callback to refetch partners
                />
            )}

            <ViewUserDialog
                open={open}
                handleClose={handleClose}
                selectedRow={selectedRow}
                user={viewRow}
            />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                pauseOnHover
            />
            {showSuccessPop && (
                <UserCreationSuccessPopup handleClose={handleClosePop} />
            )}
            {showFailedPop && (
                <UserCreationFailedPopup handleClose={handleClosePop} />
            )}
        </Box>
    );
};

export default SdlistTable;
