import { Grid } from "@material-ui/core";
import {
  Box,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Link,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import flight from "../Assets/Services/Flight/flight.svg";
import EastIcon from "@mui/icons-material/East";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FlightIcon from "@mui/icons-material/Flight";
import LuggageIcon from "@mui/icons-material/Luggage";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import axios from "axios";
// import { toast } from "react-toastify";
import RightSwipeableDrawer from "./RightSwipeableDrawer";
import SeatDrawer from "./SeatDrawer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import MealDrawer from "./MealDrawer";
import BaggageDrawer from "./BaggageDrawer";
import { toast } from "react-toastify";

function BookingDetails() {
  const jwtToken = sessionStorage.getItem("jwtToken");
  const [mobileNumber, setMobileNumber] = useState("+91 ");
  const [fareRuleDetail, setFareRuleDetail] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [seatDrawerOpen, setSeatDrawerOpen] = useState(false);
  const [mealDrawerOpen, setMealDrawerOpen] = useState(false);
  const [baggageDrawerOpen, setBaggageDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [fareQuote, setfareQuote] = useState({});
  const [publishedFare1, setPublishedFare1] = useState(0);

  const [mealData, setMealData] = useState();
  const [mealDynamicData, setMealDynamicData] = useState();
  const [baggageData, setBaggageData] = useState();
  const [seatDynamicData, setSeatDynamicData] = useState();
  const [seatPreferenceData, setSeatPreferenceData] = useState();

  const toggleMealDrawer = (state) => () => {
    setMealDrawerOpen(state);
  };
  const toggleSeatDrawer = (state) => () => {
    setSeatDrawerOpen(state);
  };
  const toggleBaggageDrawer = (state) => () => {
    setBaggageDrawerOpen(state);
  };

  const result = fareQuote.Response?.Results || [];
  // console.log("Response.Results---", result);

  const IsLCC = result?.IsLCC;
  const IsHoldAllowedWithSSR = result?.IsHoldAllowedWithSSR;
  const IsPassportRequiredAtBook = result?.IsPassportRequiredAtBook;
  const IsPassportRequiredAtTicket = result?.IsPassportRequiredAtTicket;

  // console.log("IsLCC: ", IsLCC);
  // console.log("IsHoldAllowedWithSSR: ", IsHoldAllowedWithSSR);
  // console.log("IsPassportRequiredAtBook: ", IsPassportRequiredAtBook);
  // console.log("IsPassportRequiredAtTicket: ", IsPassportRequiredAtTicket);

  const navigate = useNavigate();
  const location = useLocation();
  const { fdetails, traceId, searchDetails } = location.state || {};

  const adults = searchDetails.adults;
  const children = searchDetails.children;
  const infants = searchDetails.infants;
  const travelers = searchDetails.travelers;

  const publishedFare = (publishedFare1 * travelers).toFixed(0);
  const totalFares = (publishedFare * 1.11).toFixed(0);
  const totalTaxes = (totalFares - publishedFare).toFixed(0);

  const durationCalculator = (source, destination) => {
    const start = new Date(source);
    const end = new Date(destination);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Invalid Date";
    }

    const diffInMs = end.getTime() - start.getTime();

    const diffInHours = diffInMs / (1000 * 60 * 60);
    const hours = Math.floor(diffInHours);
    const minutes = Math.round((diffInHours - hours) * 60);

    return `${hours} hrs ${minutes} mins`;
  };

  const importAllLogos = (requireContext) => {
    const logos = {};
    requireContext.keys().forEach((key) => {
      const airlineCode = key.replace("./", "").replace(".gif", "");
      logos[airlineCode] = requireContext(key);
    });
    return logos;
  };

  const airlineLogos = importAllLogos(
    require.context("../Assets/Services/Flight/logos", false, /\.gif$/)
  );

  const handleInputChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleFareRules = async () => {
    if (!jwtToken) {
      alert("Authentication token is missing!");
      return;
    }

    const apiUrl = "https://testapi.ipaisa.co.in/myapp/flight/farerule";
    const requestBody = {
      traceId,
      resultIndex: fdetails.ResultIndex,
    };
    setLoading(true);
    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      const { Response } = response.data;

      if (Response?.Error?.ErrorCode !== 0) {
        console.warn("API returned an error:", Response.Error?.ErrorMessage);
        toast.warn(
          Response.Error?.ErrorMessage || "Failed to retrieve flight details."
        );
        return;
      }

      if (Response?.FareRules?.length > 0) {
        const fareRuleDetail = Response.FareRules[0].FareRuleDetail;
        setFareRuleDetail(fareRuleDetail);
        setDrawerOpen(true);
      } else {
        toast.warn("No fare rules available for this flight.");
      }
    } catch (error) {
      console.error("Error fetching flight details:", error);
      toast.error("Failed to retrieve flight details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFareQuoteAndSSR = async () => {
      try {
        // Fetch Fare Quote
        const fareQuoteUrl =
          "https://testapi.ipaisa.co.in/myapp/flight/farequote";
        const fareQuotePayload = {
          traceId: traceId,
          resultIndex: fdetails.ResultIndex,
        };

        const fareQuoteResponse = await axios.post(
          fareQuoteUrl,
          fareQuotePayload,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (fareQuoteResponse?.data?.Response?.Error?.ErrorCode === 0) {
          const apiResponse = fareQuoteResponse.data;
          const pFare1 =
            fareQuoteResponse.data.Response.Results.Fare.PublishedFare;

          setfareQuote(apiResponse);
          setPublishedFare1(pFare1);

          // Fetch SSR after Fare Quote is successful
          const ssrUrl = "https://testapi.ipaisa.co.in/myapp/flight/ssr";
          const ssrPayload = {
            traceId: traceId,
            resultIndex: fdetails.ResultIndex,
          };

          const ssrResponse = await axios.post(ssrUrl, ssrPayload, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
          });

          if (ssrResponse?.data?.Response?.Error?.ErrorCode === 0) {
            console.log("SSR API Data", ssrResponse.data);
            setMealData(ssrResponse?.data?.Response?.Meal || "");
            setMealDynamicData(ssrResponse?.data?.Response?.MealDynamic || "");
            setBaggageData(ssrResponse?.data?.Response?.Baggage || "");
            setSeatDynamicData(ssrResponse?.data?.Response?.SeatDynamic || "");
            setSeatPreferenceData(
              ssrResponse?.data?.Response?.SeatPreference || ""
            );
          } else {
            // alert(ssrResponse.data.Response.Error.ErrorMessage);
            // toast.error(
            //   ssrResponse.data.Response.Error.ErrorMessage ||
            //     "Session expire, Please try again!"
            // );
          }
        } else {
          toast.error(
            fareQuoteResponse.data.Response.Error.ErrorMessage ||
              "Session expire, Please try again!"
          );
        }
      } catch (error) {
        console.error("Error in API calls:", error);
      }
    };

    fetchFareQuoteAndSSR();
  }, []);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
          nationality: country.demonyms?.eng?.f || "N/A",
        }));
        const sortedCountries = countryData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleSelect = (event) => {
    setSelectedCountry(event.target.value);
  };

  return (
    <Container px={3} maxWidth="lg" sx={{ marginTop: "8%" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography>Service &gt; Flight Booking</Typography>
        <Link
          onClick={() => navigate(-1)}
          sx={{
            textDecoration: "none",
            ":hover": {
              cursor: "pointer",
            },
            mt: 0.5,
            color: "#027BFE",
          }}
        >
          &lt; Back to Search
        </Link>
      </Box>

      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Paper sx={{ p: 2, borderRadius: "10px" }}>
              {/* Render flight details */}
              <Box>
                {fdetails?.Segments?.map((segmentGroup, index) => {
                  const source = segmentGroup[0].Origin.Airport.CityName;
                  const depTime = segmentGroup[0].Origin.DepTime;
                  const destination =
                    segmentGroup[segmentGroup.length - 1].Destination.Airport
                      .CityName;
                  const totalDuration = durationCalculator(
                    segmentGroup[0].Origin.DepTime,
                    segmentGroup[segmentGroup.length - 1].Destination.ArrTime
                  );

                  return (
                    <Box key={index}>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Typography fontWeight={300} fontSize={14}>
                            Depart
                          </Typography>
                          <Typography fontWeight={600} fontSize={18}>
                            {source}
                          </Typography>
                          <EastIcon />
                          <Typography fontWeight={600} fontSize={18}>
                            {destination}
                          </Typography>
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          gap={2}
                          alignItems={"center"}
                        >
                          <Button
                            onClick={handleFareRules}
                            disabled={loading}
                            sx={{
                              color: "#FF0000",
                              textTransform: "none",
                              borderRadius: "20px",
                            }}
                            endIcon={<ErrorOutlineIcon />}
                          >
                            {loading ? (
                              <CircularProgress
                                size={20}
                                sx={{ color: "#FF0000" }}
                              />
                            ) : (
                              "Fare Rules"
                            )}
                          </Button>
                          <RightSwipeableDrawer
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            onOpen={() => setDrawerOpen(true)}
                            content={fareRuleDetail}
                          />
                        </Box>
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <Typography>
                          {new Date(depTime).toLocaleDateString("en-US", {
                            weekday: "short",
                            day: "2-digit",
                            month: "short",
                            year: "2-digit",
                          })}
                        </Typography>
                        {"|"}
                        <Typography>
                          {segmentGroup.length === 1
                            ? "Direct Flight"
                            : `${segmentGroup.length - 1} stop${
                                segmentGroup.length - 1 > 1 ? "s" : ""
                              }, via ${segmentGroup
                                .slice(0, segmentGroup.length - 1)
                                .map(
                                  (stop) => stop.Destination.Airport.CityName
                                )
                                .join(", ")}`}
                        </Typography>
                        {"|"}
                        <Typography>{totalDuration}</Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                {fdetails?.Segments?.map((segmentGroup, index) => (
                  <Box key={index} mb={3} borderRadius={2}>
                    {segmentGroup.map((segment, segIndex) => (
                      <Box key={segIndex} mt={2}>
                        <Box
                          sx={{
                            background: "#F5F7FA",
                            p: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box display={"flex"} flexDirection={"row"} gap={2}>
                            <img
                              src={
                                airlineLogos[segment.Airline.AirlineCode] ||
                                "../Assets/Services/Flight/logos/default.gif"
                              }
                              alt={`${segment.Airline.AirlineName} logo`}
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "10px",
                              }}
                            />
                            <Box>
                              <Typography>
                                {segment.Airline.AirlineName}
                              </Typography>
                              <Typography>
                                {segment.Airline.AirlineCode}
                                {" - "}
                                {segment.Airline.FlightNumber}
                              </Typography>
                            </Box>
                          </Box>

                          <Box>
                            <Typography>Baggage: {segment.Baggage}</Typography>
                            <Typography>
                              Cabin Baggage: {segment.CabinBaggage}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          px={16}
                          mt={2}
                        >
                          <Box textAlign={"right"}>
                            <Typography fontWeight={700} fontSize={"20px"}>
                              {new Date(segment.Origin.DepTime).toLocaleString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </Typography>

                            <Typography fontSize={"18px"}>
                              {new Date(segment.Origin.DepTime).toLocaleString(
                                "en-US",
                                {
                                  weekday: "short",
                                  day: "2-digit",
                                  month: "short",
                                  year: "2-digit",
                                }
                              )}
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              {segment.Origin.Airport.CityName}{" "}
                              <strong>
                                [ {segment.Origin.Airport.AirportCode} ]
                              </strong>
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              {segment.Origin.Airport.AirportName}
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              Terminal{" "}
                              {segment.Origin.Airport.Terminal || "---"}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography color="#027BFE">
                              {`${Math.floor(segment.Duration / 60)} Hr. ${
                                segment.Duration % 60
                              } Mins`}
                            </Typography>
                            <Box display={"flex"} flexDirection={"row"}>
                              <Typography color="#027BFE">
                                - - - - -{" "}
                              </Typography>
                              <FlightIcon
                                sx={{
                                  rotate: "90deg",
                                  color: "#027BFE",
                                  ml: 0.5,
                                }}
                              />
                              <Typography color="#027BFE">
                                {" "}
                                - - - - -
                              </Typography>
                            </Box>
                          </Box>

                          <Box textAlign={"left"}>
                            <Typography fontWeight={700} fontSize={"22px"}>
                              {new Date(
                                segment.Destination.ArrTime
                              ).toLocaleString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </Typography>
                            <Typography fontSize={"18px"}>
                              {new Date(
                                segment.Destination.ArrTime
                              ).toLocaleString("en-US", {
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "2-digit",
                              })}
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              {segment.Destination.Airport.CityName}{" "}
                              <strong>
                                [ {segment.Destination.Airport.AirportCode} ]
                              </strong>
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              {segment.Destination.Airport.AirportName}
                            </Typography>
                            <Typography fontWeight={300} fontSize={"12px"}>
                              Terminal{" "}
                              {segment.Destination.Airport.Terminal || "---"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Paper>
            <Paper sx={{ mt: 3, p: 2, borderRadius: 3 }}>
              <Typography
                fontWeight={700}
                fontSize={"20px"}
                fontFamily={"open sans"}
              >
                Traveller Details
              </Typography>
              <Typography
                fontWeight={300}
                fontSize={"14px"}
                fontFamily={"open sans"}
                mt={1}
              >
                Please make sure you enter the Name as per your Government photo
                id.
              </Typography>
              <Box>
                {Array.from({ length: adults }).map((_, index) => (
                  <Box key={`adult-${index}`} sx={{ mt: 2 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={"16px"}
                      fontFamily={"open sans"}
                      mb={1}
                    >
                      Adult {index + 1}
                    </Typography>

                    <Grid container spacing={2} mt={2}>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Title*"
                        >
                          <MenuItem value="Mr">Mr.</MenuItem>
                          <MenuItem value="Ms">Ms.</MenuItem>
                          <MenuItem value="Mrs">Mrs.</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="First Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Last Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="DOB*"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Gender*"
                        >
                          <MenuItem value="1">Male</MenuItem>
                          <MenuItem value="2">Female</MenuItem>
                          <MenuItem value="3">Other</MenuItem>
                        </TextField>
                      </Grid>
                      {IsPassportRequiredAtTicket && (
                        <>
                          <Grid item md={3}>
                            <TextField
                              select
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              name="nationality"
                              label="Nationality"
                              value={selectedNationality}
                              onChange={(e) =>
                                setSelectedNationality(e.target.value)
                              }
                              sx={{ marginBottom: 2 }}
                            >
                              <MenuItem value="">Select Nationality</MenuItem>
                              {countries.map((country) => (
                                <MenuItem
                                  key={country.code}
                                  value={country.nationality}
                                >
                                  {country.nationality}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              label="Passport No."
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              label="Passport Expiry"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 1*"
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 2*"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="City*"
                        />
                      </Grid>

                      <Grid item md={2}>
                        <TextField
                          select
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country Code"
                          value={selectedCountryCode}
                          onChange={(e) =>
                            setSelectedCountryCode(e.target.value)
                          }
                          sx={{ marginBottom: 2 }}
                        >
                          <MenuItem value="">Select Country Code</MenuItem>
                          {countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.code}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          select
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country"
                          value={selectedCountry}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                        >
                          <MenuItem value="">Select Country</MenuItem>
                          {countries.map((country) => (
                            <MenuItem key={country.name} value={country.name}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {Array.from({ length: children }).map((_, index) => (
                  <Box key={`child-${index}`} sx={{ mt: 3 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={"16px"}
                      fontFamily={"open sans"}
                      mb={1}
                    >
                      Child {index + 1}
                    </Typography>
                    <Grid container spacing={2} mt={2}>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Title*"
                        >
                          <MenuItem value="Mr">Mr.</MenuItem>
                          <MenuItem value="Ms">Ms.</MenuItem>
                          <MenuItem value="Mrs">Mrs.</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="First Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Last Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="DOB*"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Gender*"
                        >
                          <MenuItem value="1">Male</MenuItem>
                          <MenuItem value="2">Female</MenuItem>
                          <MenuItem value="3">Other</MenuItem>
                        </TextField>
                      </Grid>
                      {IsPassportRequiredAtTicket && (
                        <>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              label="Nationality"
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              label="Passport No."
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              label="Passport Expiry"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 1*"
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 2*"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="City*"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country Code"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {Array.from({ length: infants }).map((_, index) => (
                  <Box key={`infant-${index}`} sx={{ mt: 3 }}>
                    <Typography
                      fontWeight={600}
                      fontSize={"16px"}
                      fontFamily={"open sans"}
                      mb={1}
                    >
                      Infant {index + 1}
                    </Typography>
                    <Grid container spacing={2} mt={2}>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Title*"
                        >
                          <MenuItem value="Mr">Mr.</MenuItem>
                          <MenuItem value="Ms">Ms.</MenuItem>
                          <MenuItem value="Mrs">Mrs.</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="First Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Last Name*"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="DOB*"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          select
                          label="Gender*"
                        >
                          <MenuItem value="1">Male</MenuItem>
                          <MenuItem value="2">Female</MenuItem>
                          <MenuItem value="3">Other</MenuItem>
                        </TextField>
                      </Grid>
                      {IsPassportRequiredAtTicket && (
                        <>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              label="Nationality"
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              label="Passport No."
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              InputProps={{ sx: { borderRadius: 4 } }}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              label="Passport Expiry"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 1*"
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Address Line 2*"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="City*"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country Code"
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          InputProps={{ sx: { borderRadius: 4 } }}
                          fullWidth
                          label="Country"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Paper>

            <Paper sx={{ mt: 3, p: 2, borderRadius: 3 }}>
              <Typography
                fontWeight={700}
                fontSize={"18px"}
                fontFamily={"open sans"}
              >
                Contact Information
              </Typography>
              <Typography
                fontWeight={300}
                fontSize={"14px"}
                fontFamily={"open sans"}
                mt={2}
              >
                Your ticket and flights information will be sent here..
              </Typography>

              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                justifyContent={"space-between"}
                gap={2}
              >
                <TextField
                  fullWidth
                  label="Mobile Number*"
                  value={mobileNumber}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: { borderRadius: 4 },
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
                <TextField
                  InputProps={{ sx: { borderRadius: 4 } }}
                  fullWidth
                  label="Email*"
                />
              </Box>
            </Paper>

            <Paper sx={{ mt: 3, p: 2, borderRadius: 3 }}>
              <Typography
                fontWeight={700}
                fontSize={"18px"}
                fontFamily={"open sans"}
              >
                Use GSTIN for this booking (Optional)
              </Typography>
              <Typography
                fontWeight={300}
                fontSize={"14px"}
                fontFamily={"open sans"}
                mt={2}
              >
                Claim credit of GST charges. Your taxes may get updated post
                submitting your GST details
              </Typography>

              <Box
                display={"flex"}
                flexDirection={"row"}
                mt={2}
                justifyContent={"space-between"}
                gap={2}
              >
                <TextField
                  InputProps={{ sx: { borderRadius: 4 } }}
                  label="GSTIN"
                />
              </Box>
            </Paper>

            <Paper sx={{ mt: 3, p: 2, borderRadius: 3 }}>
              <Typography
                fontWeight={700}
                fontSize={"18px"}
                fontFamily={"open sans"}
              >
                Addon Services
              </Typography>
              <Box mt={2} display={"flex"} flexDirection={"row"} gap={2}>
                <Button
                  onClick={toggleMealDrawer(true)}
                  variant="outlined"
                  startIcon={<FastfoodIcon />}
                  sx={{ color: "#027BFE", textTransform: "none" }}
                >
                  Add Meals
                </Button>
                <MealDrawer
                  open={mealDrawerOpen}
                  onClose={toggleMealDrawer(false)}
                  onOpen={toggleMealDrawer(true)}
                  mealData={mealData}
                  mealDynamicData={mealDynamicData}
                />
                <Button
                  onClick={toggleBaggageDrawer(true)}
                  variant="outlined"
                  startIcon={<LuggageIcon />}
                  sx={{ color: "#027BFE", textTransform: "none" }}
                >
                  Add Baggage
                </Button>
                <BaggageDrawer
                  open={baggageDrawerOpen}
                  onClose={toggleBaggageDrawer(false)}
                  onOpen={toggleBaggageDrawer(true)}
                  baggageData={baggageData}
                />
                <Button
                  onClick={toggleSeatDrawer(true)}
                  variant="outlined"
                  startIcon={<FlightClassIcon />}
                  sx={{ color: "#027BFE", textTransform: "none" }}
                >
                  Seat Selection
                </Button>
                <SeatDrawer
                  open={seatDrawerOpen}
                  onClose={toggleSeatDrawer(false)}
                  onOpen={toggleSeatDrawer(true)}
                  seatDynamicData={seatDynamicData}
                  seatPreferenceData={seatPreferenceData}
                />
              </Box>
            </Paper>

            <Box display={"flex"} justifyContent={"flex-end"} mt={3} mb={4}>
              <Button
                sx={{
                  background: "#027BFE",
                  color: "white",
                  textTransform: "none",
                  borderRadius: "10px",
                  px: 4,
                  py: 1,
                }}
              >
                Continue to Payment
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper sx={{ p: 2, borderRadius: "10px" }}>
              <Box sx={{ mt: 1 }}>
                <Box display={"flex"} flexDirection={"row"} gap={1} mb={1}>
                  <InfoOutlinedIcon sx={{ color: "red" }} />
                  <Typography sx={{ color: "red" }}>
                    Fare may increase/update at the time of booking.
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Fare Details
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                      }}
                    >
                      Base Fare
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      ₹ {publishedFare}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        //   fontWeight: 700,
                      }}
                    >
                      Taxes
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      ₹ {totalTaxes}
                    </Typography>
                  </Box>

                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      ₹ {totalFares}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>

            {/* Promo code */}
            {/* <Paper sx={{ p: 2, borderRadius: "10px", mt: 2 }}>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Promo code
              </Typography>
              <TextField
                InputProps={{ sx: { borderRadius: 2 } }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="Enter Code"
                sx={{
                  "& .MuiInputBase-root": {
                    height: { xs: "40px", md: "48px" },
                  },
                  mt: 1,
                }}
              />
              <Button
                sx={{
                  background: "#027BFE",
                  color: "white",
                  textTransform: "none",
                  px: 2,
                  width: "100px",
                  borderRadius: "10px",
                  mt: 1
                }}
              >
                Apply
              </Button>
            </Paper> */}

            <img
              style={{
                width: "100%",
                height: "250px",
                marginTop: "20px",
                borderRadius: "10px",
              }}
              src="https://i.pinimg.com/originals/cc/a5/02/cca5022c86f67861746d7cf2eb486de8.gif"
              alt="flight_ad"
            />
            <img
              style={{
                width: "100%",
                height: "250px",
                marginTop: "20px",
                borderRadius: "10px",
              }}
              src="https://i.pinimg.com/originals/cc/a5/02/cca5022c86f67861746d7cf2eb486de8.gif"
              alt="flight_ad"
            />
            <img
              style={{
                width: "100%",
                height: "250px",
                marginTop: "20px",
                borderRadius: "10px",
              }}
              src="https://i.pinimg.com/originals/cc/a5/02/cca5022c86f67861746d7cf2eb486de8.gif"
              alt="flight_ad"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default BookingDetails;
