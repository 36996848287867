import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Container,
  Paper,
  Box,
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PartnerArrowIcon } from '../../../res/NewWebIcons';

const AddSubAdmin = () => {
  const initialFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    utype: 'SUBADMIN',
    ftr: false,
    qqr: false,
    kqr: false,
    cc: false,
    ctr: false,
    rmr: false,
    cr: false,
    hwr: false,
    pr: false,
    tpayouts: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Mobile number validation
    if (name === 'mobileNumber') {
      if (!/^\d*$/.test(value)) return; // Allow only numbers
      if (value.length > 10) return; // Limit to 10 digits
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First Name is required.';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required.';
    if (!formData.mobileNumber.trim() || formData.mobileNumber.length !== 10)
      newErrors.mobileNumber = 'Mobile number must be 10 digits.';
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Enter a valid email address.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(
        'https://testapi.ipaisa.co.in/myapp/auth/subadminRegister',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Response:', response.data);
      toast.success('Sub-admin registered successfully!', {
        autoClose: 3000,
      });
      handleReset(); // Reset form after successful submission
    } catch (error) {
      console.error('Error:', error.response);
      const errorMessage =
        error.response?.data?.message || 'Failed to register sub-admin.';
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setErrors({});
  };

  return (
    <Box style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <ToastContainer />
      <Grid>
        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1%',
            justifyContent: 'start',
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
          >
            Sub Admin
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: 'sans-serif', fontSize: '13px' }}
          >
            Add Sub-Admin
          </Typography>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1%',
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                fontSize: '20px',
                color: '#343C6A',
              }}
            >
              Add Sub-Admin
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Paper
        sx={{
          padding: '2rem',
          borderRadius: '12px',
          boxShadow: 'none',
          marginTop: '2%',
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Middle Name"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                />
              </Grid>
              {[
                { label: 'Queen QR Report', name: 'qqr' },
                { label: 'King QR Report', name: 'kqr' },
                { label: 'Credit Card Report', name: 'cc' },
                { label: 'Completed Transaction Report', name: 'ctr' },
                { label: 'Reverse Money Report', name: 'rmr' },
                { label: 'Commission Report', name: 'cr' },
                { label: 'Hierarchy Wise Report', name: 'hwr' },
                { label: 'Pending Report', name: 'pr' },
                { label: 'Total Payout Report', name: 'tpayouts' },
              ].map((checkbox) => (
                <Grid item xs={12} sm={6} key={checkbox.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={checkbox.name}
                        checked={formData[checkbox.name]}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={checkbox.label}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                gap: '1%',
                justifyContent: 'end',
                marginTop: '3%',
              }}
            >
              <Button
                type="button"
                sx={{
                  border: '1.5px solid #FF0000',
                  color: '#FF0000',
                  textTransform: 'none',
                  height: '46px',
                  fontSize: '18px',
                }}
                fullWidth
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  color: 'white',
                  backgroundColor: '#027BFE',
                  textTransform: 'none',
                  height: '46px',
                  fontSize: '18px',
                }}
                fullWidth
              >
                Add
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AddSubAdmin;
