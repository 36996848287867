import React, { useEffect, useState } from "react";
import ServiceTabs from "../NewServices/Tabs";
import MobileRecharge from "./MobileRecharges/MobileRecharge";
import { Grid, Paper } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import NewElectricity2 from "../NewServices/NewElectricity2";
import NewTVRecharge from "./NewTVRecharge";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import NewWaterBill from "./NewWaterBill";
import GasBillingScreen from "./GasBills/GasBillingScreen";
import NewPayLoan from "./NewPayLoan";
import NewBroadbandlandline from "./NewBroadbandlandline";
import NewInsurance from "./NewInsurance";
import { useLocation } from "react-router-dom";

export default function RetailerServices() {
  const location = useLocation();
  const { label, categoryKey } = location.state || {};
  const [selectedService, setSelectedService] = useState("");
  const [billers, setBillers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  console.log(categoryKey);

  // Fetch billers by categoryKey
  const fetchBillersByCategory = async (key) => {
    try {
      setLoading(true);
      setError("");

      // Retrieve JWT token from sessionStorage
      const jwtToken = sessionStorage.getItem("jwtToken");
      // if (!jwtToken) {
      //   throw new Error("JWT token not found in sessionStorage");
      // }

      // API URL
      const apiUrl = `https://testapi.ipaisa.co.in/myapp/billPayments/getBillersBycategory?categoryKey=${key}&page=0&size=100`;

      // Fetch data
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      // if (!response.ok) {
      //   throw new Error(`Failed to fetch billers: ${response.statusText}`);
      // }

      const data = await response.json();
      setBillers(data); // Set fetched billers
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedService(label || "Electricity Bills");
    console.log("Selected Service: ", label);

    if (categoryKey) {
      fetchBillersByCategory(categoryKey);
    }
  }, [label, categoryKey]);

  // Mapping object for service components
  const serviceComponents = {
    "Mobile Recharge": <MobileRecharge />,
    "Credit Card": <MobileRecharge />, // Replace if needed
    "Electricity": <NewElectricity2 />,
    "DTH": <NewTVRecharge />,
    "Cable TV": <NewTVRecharge />,
    "Broadband": <NewBroadbandlandline />,
    "Gas Cylinder": <GasBillingScreen />,
    "Gas (PNG)": <GasBillingScreen />,
    "Education Fee": <NewPayLoan />,
    "Water": <NewWaterBill />,
    "Pay Loans": <NewPayLoan />,
    "Landline": <NewBroadbandlandline />,
    "Rental": <MobileRecharge />, // Replace if needed
    "Donation": <MobileRecharge />, // Replace if needed
    "FASTag Recharge": <MobileRecharge />, // Replace if needed
    "Insurance": <NewInsurance />,
    "Hospital & Pathology": <MobileRecharge />, // Replace if needed
    "Housing Society": <NewInsurance />,
    "Municipal Taxes": <MobileRecharge />, // Replace if needed
    "Recurring Deposit": <MobileRecharge />, // Replace if needed
    "Subscription": <MobileRecharge />, // Replace if needed
    "Pay Loans": <MobileRecharge />, // Replace if needed
    "NCMC Recharge": <MobileRecharge />, // Replace if needed
    "National Pension Scheme (NPS)": <MobileRecharge />, // Replace if needed
    "Electricity (Prepaid)": <NewElectricity2 />, // Replace if needed
    "Clubs & Associations": <NewElectricity2 />, // Replace if needed
  };

  // Render selected service component or default component
  const renderServiceComponent = () => {
    return serviceComponents[selectedService] || <MobileRecharge />;
  };

  return (
    <Box style={{ padding: "2rem", marginTop: "calc(2% + 58px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Services
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Services
        </Typography>
      </Grid>

      <Grid
        style={{
          marginTop: "1%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Services
        </Typography>
      </Grid>
      <Paper
        style={{
          boxShadow: "none",
          borderRadius: "18px 18px 18px 18px",
          marginTop: "2%",
        }}
      >
        <Grid>
          <ServiceTabs
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
          {renderServiceComponent()}
        </Grid>
        {loading && <Typography>Loading billers...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {billers.length > 0 && (
          <Box>
            <Typography>Biller List:</Typography>
            <ul>
              {billers.map((biller, index) => (
                <li key={index}>{biller.name}</li>
              ))}
            </ul>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
